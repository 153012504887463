import "./styles.scss";
import React from "react";
import { Box, Grid } from "@material-ui/core";
import { ContentWrapper } from "../../../../ContentWrapper";
import { SmoothBg } from "../../../../SmoothBg";
import {
  SectionRenderingComponents,
  ContentComponent,
  ContentContainer,
  sort,
} from "../../../Page";
import { contents } from "../contents";
import { ImagesCube } from "../../../../ImagesCube";

const components = { contents };

const excludeSubmitBanner = ({ handle }) => handle !== "home.hero.submit";
const keepSubmitBanner = ({ handle }) => handle === "home.hero.submit";

const DefaultContentRender = ({ id, handle, title, body, picture }) => {
  return (
    <ContentContainer key={id} id={id} handle={handle}>
      <ContentComponent
        id={id}
        handle={handle}
        title={title}
        body={body}
        picture={picture}
        components={components}
      />
    </ContentContainer>
  );
};

export const sections: SectionRenderingComponents = {
  "home.hero": ({ contents }) => (
    <>
      <div id="EngerizeHeroContainer">
        <Grid
          id="EngeriizeHomePage"
          className="EngeriizeHomePage section_container"
          container
        >
          <Grid id="EnergiizeHeroSection" spacing={0} container>
            <Grid xs={12} md={5} item>
              {sort(contents)
                .filter(excludeSubmitBanner)
                .map(DefaultContentRender)}
            </Grid>
            <Grid className="ImagesCube--container" xs={12} md={7} item>
              <ImagesCube />
            </Grid>
          </Grid>
        </Grid>
      </div>
      {contents.filter(keepSubmitBanner).map(DefaultContentRender)}
    </>
  ),
  "home.how-to-participate": ({ contents }) => (
    <SmoothBg mb={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
      <ContentWrapper>
        <Box>{sort(contents).map(DefaultContentRender)}</Box>
      </ContentWrapper>
    </SmoothBg>
  ),
  "home.irc": ({ contents }) => <>{sort(contents).map(DefaultContentRender)}</>,
};
