import React from "react";
import { SectionTitle, SectionTitleProps } from "../SectionTitle";
import { useAnimateOnceOnScroll } from "../../hooks";

export interface AnimatedSectionTitleProps extends Partial<SectionTitleProps> {}

export const AnimatedSectionTitle: React.FC<AnimatedSectionTitleProps> = ({
  variant,
  children,
  kind = "default",
  ...sectionTitleProps
}) => {
  const { ref, animate } = useAnimateOnceOnScroll();
  const props = {
    ...sectionTitleProps,
    kind,
    ref,
    variant,
    className: `animated-underline ${
      animate ? "animated-underline--active" : ""
    }`,
  };

  return <SectionTitle {...props}>{children}</SectionTitle>;
};

AnimatedSectionTitle.displayName = "AnimatedSectionTitle";
