import React from "react";
import { useOnDemandMarkupTranslation } from "../../../../hooks";
import { Content } from "../../../../models";

export function ContentWithOnDemandTranslation(Component: React.FC<Content>) {
  function RenderWithOnDemandTranslation(content: Content) {
    const title = useOnDemandMarkupTranslation(content?.title || "");
    const body = useOnDemandMarkupTranslation(content?.body || "");

    return <Component {...content} title={title} body={body} />;
  }

  RenderWithOnDemandTranslation.displayName = "RenderWithOnDemandTranslation";

  return RenderWithOnDemandTranslation;
}

ContentWithOnDemandTranslation.displayName = "ContentWithOnDemandTranslation";
