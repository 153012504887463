import "./styles.scss";
import React, { ReactNode, useState } from "react";
import { Box, BoxProps, StyleRules, useMediaQuery, useTheme } from "@material-ui/core";

export interface FlippingCardProps extends Partial<BoxProps> {
  front: ReactNode;
  back?: ReactNode;
  className?: string;
  frontStyles?: StyleRules;
  backStyles?: StyleRules;
  flipOnHover?: boolean;
  isFlipped?: boolean;
  onClick?: () => void;
  "data-testid"?: string;
}

export const TIMEOUT = 600;

const noop = () => null;

export const FlippingCard: React.FC<FlippingCardProps> = ({
  front,
  back,
  className = "",
  frontStyles = {},
  backStyles = {},
  flipOnHover = false,
  isFlipped = false,
  onClick: handleClick = noop,
  sx,
  ...props
}) => {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      role="presentation"
      className="FlippingCard--container"
      data-testid={props["data-testid"]}
      onClick={!isHovering ? handleClick : noop}
      onMouseEnter={() => setIsHovering(!isMobileDevice)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Box
        {...props}
        className={`FlippingCard ${className}${
          isFlipped || isHovering ? " is-flipped" : ""
        }`}
        sx={sx}
      >
        <Box
          className="FlippingCard__front"
          sx={{
            ...frontStyles,
            borderRadius: sx?.borderRadius,
          }}
        >
          {front}
        </Box>
        {back && (
          <Box
            className="FlippingCard__back"
            sx={{
              ...backStyles,
              borderRadius: sx?.borderRadius,
            }}
          >
            {back}
          </Box>
        )}
      </Box>
    </div>
  );
};

FlippingCard.displayName = "FlippingCard";
