import { useAmplifyFileUrl } from "../useAmplifyFileUrl";

export function useAmplifyContentImage(
  imageName: string | null | undefined,
  placeholderURL: string | undefined = undefined
) {
  const { url: resolvedUrl } = useAmplifyFileUrl(imageName);
  const url = resolvedUrl || placeholderURL;

  return { url };
}
