import React from "react";
import { Page } from "../Page";
import { contents, sections } from "./components";

export interface InfrastructureProps {}

export const INFRASTRUCTURE = "infrastructure";
export const path = INFRASTRUCTURE;
export const title = "Infrastructure";

export const Infrastructure: React.FC<InfrastructureProps> = () => {
  return <Page handle={INFRASTRUCTURE} components={{ contents, sections }} pt={0} pb={6} />;
};

Infrastructure.displayName = "Infrastructure";
