import { Paragraph } from "../../Paragraph";
import { Markdown } from "../Markdown";
import { Content } from "../../../models";

export const MarkdownParagraph: React.FC<Pick<Content, "body">> = ({
  body,
}) => (
  <Paragraph>
    <Markdown>{body}</Markdown>
  </Paragraph>
);

MarkdownParagraph.displayName = "MarkdownParagraph";
