import React from "react";
import { Grid, Box } from "@material-ui/core";
import { ContentWrapper } from "../../../../ContentWrapper";
import { SmoothBg } from "../../../../SmoothBg";
import {
  SectionRenderingComponents,
  ContentComponent,
  ContentContainer,
  sort,
} from "../../../Page";
import { contents as contentsComponents } from "../contents";

export const sections: SectionRenderingComponents = {
  "infrastructure.eligible-equipments": ({ contents }) => (
    <SmoothBg>
      <ContentWrapper>
        <Box>
          <Grid spacing={5} container>
            {sort(contents).map(({ id, handle, title, body, picture }) => {
              return (
                <Grid
                  key={id}
                  item
                  xs={12}
                  md={
                    [
                      "infrastructure.eligible-equipments.paragraph",
                      "infrastructure.eligible-equipments.interested",
                    ].includes(handle)
                      ? 12
                      : 6
                  }
                  data-model="content"
                  data-id={id}
                  data-handle={handle}
                >
                  <ContentContainer key={id} id={id} handle={handle}>
                    <ContentComponent
                      id={id}
                      handle={handle}
                      title={title}
                      body={body}
                      picture={picture}
                      components={{ contents: contentsComponents }}
                    />
                  </ContentContainer>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </ContentWrapper>
    </SmoothBg>
  ),
};
