import { useContext } from "react";
import { useSelector } from "react-redux";
import { LanguagesContext } from "../../contexts";

export function usePageData(handle: string) {
  const { language } = useContext(LanguagesContext);
  const contents = useSelector((state) => state.contents);
  const source = contents[language]?.pages || [];
  const page = source.find((page) => page.handle === handle);

  return {
    page,
    error: contents?.error,
    isLoading: contents?.isLoading,
  };
}
