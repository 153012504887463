import { AnimatedSectionTitle } from "../../AnimatedSectionTitle";
import { MarkdownParagraph } from "../MarkdownParagraph";
import { Content } from "../../../models";

export const MarkdownWithMainTitle = ({
  title,
  body,
}: Pick<Content, "title" | "body">) => (
  <>
    <AnimatedSectionTitle kind="main">{title}</AnimatedSectionTitle>
    <MarkdownParagraph body={body} />
  </>
);

MarkdownWithMainTitle.displayName = "MarkdownWithMainTitle";
