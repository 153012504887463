import React from "react";

import { ContentWrapper } from "../../../../ContentWrapper";
import { SmoothBg } from "../../../../SmoothBg";
import {
  SectionRenderingComponents,
  ContentContainer,
  sort,
} from "../../../Page";
import { NewsCarousel } from "../../../../NewsCarousel";
import { NewsCarouselItem } from "../../../../NewsCarousel/NewsCarouselItem";
import { ImageText } from "../../../../ImageText";
import { WithResolvedPictureURL } from "../../../Page/WithResolvedPictureURL";
import { Grid } from "@material-ui/core";
import { AnimatedSectionTitle } from "../../../../AnimatedSectionTitle";
import { useOnDemandMarkupTranslation } from "../../../../../hooks";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";

export const props: { [key: string]: any } = {
  "news.news-and-updates": {
    pt: 0,
  },
  "news.archived-events": {
    position: "relative",
    marginBottom: "-36px",
  },
};

export const NewsAndUpdates = ({ contents }) => {
  const { title: initialTitle, body: initialBody } = contents?.find(
    ({ handle }) => handle === "news.news-and-updates.intro"
  );
  
  const title = useOnDemandMarkupTranslation(initialTitle);
  const body = useOnDemandMarkupTranslation(initialBody);
  
  const items = sort(contents)
    .filter(({ handle }) => handle !== "news.news-and-updates.intro")
    .map(
      WithResolvedPictureURL(({ id, title, body, picture, handle }) => (
      
        <NewsCarouselItem
          key={id}
          title={title}
          text={body}
          href={handle === "news.news-and-updates.learn"?"https://calstartorg.maps.arcgis.com/apps/dashboards/93ba3501edad4f51beb4d8d4dda46647":""}
          imageURL={picture || "static/media/whiteBuses.08709458.jpeg"}
        />
      ))
    );

  return <NewsCarousel title={title} body={body} items={items} />;
};

export const sections: SectionRenderingComponents = {
  "news.news-and-updates": NewsAndUpdates,
  "news.archived-events": ContentWithOnDemandTranslation(
    ({ id, contents, title, handle }) => (
      <SmoothBg hideBottom variant="green">
        <ContentWrapper>
          <AnimatedSectionTitle
            kind="default"
            variant="h1"
            style={{ paddingTop: "24px" }}
          >
            {title}
          </AnimatedSectionTitle>
          <ContentContainer id={id} handle={handle} pb={16} pt={2}>
            <Grid container spacing={4}>
              {sort(contents).map(
                ContentWithOnDemandTranslation(
                  WithResolvedPictureURL(
                    ({ id, title: contentTitle, body, picture }) => (
                      <Grid key={`${contentTitle}-${id}`} item xs={12} md={6}>
                        <ImageText
                          key={id}
                          children={contentTitle}
                          imageURL={
                            picture || "static/media/whiteBuses.08709458.jpeg"
                          }
                          link={body as string}
                        />
                      </Grid>
                    )
                  )
                )
              )}
            </Grid>
          </ContentContainer>
        </ContentWrapper>
      </SmoothBg>
    )
  ),
};
