export interface SubNavigationItemProp {
  title: string;
  handle: string;
}

export type SubNavigationMenuProp = Array<SubNavigationItemProp>;

const ContactSvg = () => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "8px" }}
  >
    <path
      d="M16.5 0.978516H2.5C1.5375 0.978516 0.75875 1.76602 0.75875 2.72852L0.75 13.2285C0.75 14.191 1.5375 14.9785 2.5 14.9785H16.5C17.4625 14.9785 18.25 14.191 18.25 13.2285V2.72852C18.25 1.76602 17.4625 0.978516 16.5 0.978516ZM16.5 4.47852L9.5 8.85352L2.5 4.47852V2.72852L9.5 7.10352L16.5 2.72852V4.47852Z"
      fill="#314962"
    />
  </svg>
);
const IPCSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "8px" }}
  >
    <g clip-path="url(#clip0_3739_29862)">
      <path
        d="M22.7669 14.7498V18.5672H13.6335V9.43382H17.4502H17.5502V9.33382V8.00049V7.90049H17.4502H13.5335C12.738 7.90049 12.1002 8.54559 12.1002 9.33382V18.6672C12.1002 19.4554 12.738 20.1005 13.5335 20.1005H22.8669C23.6554 20.1005 24.3002 19.4557 24.3002 18.6672V14.7498V14.6498H24.2002H22.8669H22.7669V14.7498ZM19.5335 7.90049H19.4335V8.00049V9.33382V9.43382H19.5335H21.6854L15.3028 15.8164L15.2321 15.8872L15.3028 15.9579L16.2428 16.8979L16.3135 16.9686L16.3842 16.8979L22.7669 10.5152V12.6672V12.7672H22.8669H24.2002H24.3002V12.6672V8.00049V7.90049H24.2002H19.5335Z"
        fill="#314962"
        stroke="#314962"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 11.4658V8.35604C11 7.50049 11.6922 6.80049 12.5556 6.80049H12.8171C12.3076 5.17723 10.7919 4.00049 9 4.00049C6.79 4.00049 5 5.79049 5 8.00049C5 10.2105 6.79 12.0005 9 12.0005C9.7287 12.0005 10.4117 11.8059 11 11.4658ZM11 14.1842C10.2638 14.0618 9.57105 14.0005 9 14.0005C6.33 14.0005 1 15.3405 1 18.0005V20.0005H11.1955C11.0709 19.7764 11 19.5186 11 19.2449V14.1842Z"
        fill="#314962"
      />
    </g>
    <defs>
      <clipPath id="clip0_3739_29862">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.000488281)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default function getNavigationMenuDefinition({
  menu_home,
  menu_about,
  menu_infrastructure,
  menu_vendor,
  menu_participate,
  menu_irc,
  menu_news,
  menu_contact,
  menu_ipc,
  sub_menu_about_story,
  sub_menu_about_principles,
  sub_menu_about_partners,
  sub_menu_about_committees,
  sub_menu_infrastructure_tech,
  sub_menu_infrastructure_ready,
  sub_menu_vendor_approved,
  sub_menu_vendor_preferred,
  sub_menu_vendor_find,
  sub_menu_vendor_become,
  sub_menu_participate_fleet,
  sub_menu_participate_process,
  sub_menu_participate_lane,
  sub_menu_irc_planning,
  sub_menu_irc_events,
  sub_menu_irc_resources,
}: {
  [key: string]: string;
}) {
  return [
    {
      title: menu_home,
      to: "/",
      handle: "home",
    },
    {
      title: menu_about,
      to: "/about",
      params: "sub_nav=true",
      handle: "about",
      subNavigationItemsProps: [
        {
          title: sub_menu_about_story,
          handle: "about.our-story",
        },
        {
          title: sub_menu_about_principles,
          handle: "about.our-core-principles",
        },
        {
          title: sub_menu_about_partners,
          handle: "about.our-partners",
        },
        {
          title: sub_menu_about_committees,
          handle: "about.advisory-committees",
        },
      ],
    },
    {
      title: menu_infrastructure,
      to: "/infrastructure",
      params: "sub_nav=true",
      handle: "infrastructure",
      subNavigationItemsProps: [
        {
          title: sub_menu_infrastructure_tech,
          handle: "infrastructure.more-details.technology",
        },
        {
          title: sub_menu_infrastructure_ready,
          handle: "infrastructure.more-details.make-ready-program",
        },
      ],
    },
    {
      title: menu_vendor,
      to: "/partners",
      params: "sub_nav=true",
      handle: "vendor",
      subNavigationItemsProps: [
        {
          title: sub_menu_vendor_approved,
          handle: "vendor.vendor-types.approved",
        },
        {
          title: sub_menu_vendor_preferred,
          handle: "vendor.vendor-types.preferred",
        },
        {
          title: sub_menu_vendor_find,
          handle: "vendor.find",
        },
        {
          title: sub_menu_vendor_become,
          handle: "vendor.want-to",
        },
      ],
    },
    {
      title: menu_participate,
      to: "/participate",
      params: "sub_nav=true",
      handle: "participate",
      subNavigationItemsProps: [
        {
          title: sub_menu_participate_fleet,
          handle: "participate.intro.commercial-fleet",
        },
        {
          title: sub_menu_participate_process,
          handle: "participate.intro.funding-process",
        },
        {
          title: sub_menu_participate_lane,
          handle: "participate.funding-process.choose-your-funding-lane",
        },
      ],
    },
    {
      title: menu_irc,
      to: "/resources",
      params: "sub_nav=true",
      handle: "irc",
      subNavigationItemsProps: [
        {
          title: sub_menu_irc_planning,
          handle: "irc.planning-for-success",
        },
        {
          title: sub_menu_irc_events,
          handle: "irc.workshops-and-webinars",
        },
        {
          title: sub_menu_irc_resources,
          handle: "irc.resources-with-tabs",
        },
      ],
    },
    {
      title: menu_news,
      handle: "news",
      to: "/updates",
    },
    {
      title: menu_contact,
      handle: "contact",
      to: "https://us20.list-manage.com/contact-form?u=97fe5ebae707b7479faeb8640&form_id=7f1004dcdf64dd293adb0c140f0a216e",
      icon: <ContactSvg />,
    },
    {
      title: menu_ipc,
      handle: "ipc",
      to: "https://energiize.force.com/apply/s/",
      icon: <IPCSvg />,
    },
  ];
}
