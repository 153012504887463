import "./styles.scss";
import React from "react";
import { FlippingCard, FlippingCardProps } from "../FlippingCard";

export interface TileProps extends Partial<FlippingCardProps> {}

export const Tile: React.FC<TileProps> = ({
  children,
  className = "",
  borderRadius = "1rem",
  flipOnHover = true,
  sx = {},
  back,
  ...props
}) => {
  const flippingCardProps = {
    ...props,
    sx: {
      ...sx,
      borderRadius,
    },
  };

  return (
    <FlippingCard
      data-testid={props["data-testid"]}
      className={`Tile ${className}`}
      front={children}
      back={back}
      {...flippingCardProps}
      flipOnHover={flipOnHover}
    >
      {children}
    </FlippingCard>
  );
};

Tile.displayName = "Tile";
