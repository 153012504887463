const PRODUCTION = "production";

const config = {
  aws_project_region:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_APPSYNC_REGION_PRODUCTION
      : process.env.REACT_APP_AWS_APPSYNC_REGION_STAGING,
  aws_appsync_graphqlEndpoint:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT_PRODUCTION
      : process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT_STAGING,
  aws_appsync_region:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_APPSYNC_REGION_PRODUCTION
      : process.env.REACT_APP_AWS_APPSYNC_REGION_STAGING,
  aws_appsync_authenticationType:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE_PRODUCTION
      : process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE_STAGING,
  aws_appsync_apiKey: 
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AMPLIFY_API_KEY_PRODUCTION
      : process.env.REACT_APP_AMPLIFY_API_KEY_STAGING,
  aws_cognito_identity_pool_id:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID_PRODUCTION
      : process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID_STAGING,
  aws_cognito_region:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_COGNITO_REGION_PRODUCTION
      : process.env.REACT_APP_AWS_COGNITO_REGION_STAGING,
  aws_user_pools_id:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_USER_POOLS_ID_PRODUCTION
      : process.env.REACT_APP_AWS_USER_POOLS_ID_STAGING,
  aws_user_pools_web_client_id:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID_PRODUCTION
      : process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID_STAGING,
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_PRODUCTION
      : process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_STAGING,
  aws_user_files_s3_bucket_region:
    process.env.NODE_ENV === PRODUCTION
      ? process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_REGION_PRODUCTION
      : process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_REGION_STAGING,
};

export default config;
