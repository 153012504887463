import { createTheme, ThemeOptions } from "@material-ui/core/styles";

const FONT_FAMILY = ["Montserrat", "sans-serif"].join(",");

export const theme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    h1: {
      fontSize: "2rem",
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      letterSpacing: 0,
    },
    h2: {
      fontSize: "1.5rem",
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      letterSpacing: 0,
    },
    h3: {
      fontSize: "1rem",
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      letterSpacing: 0,
    },
    body1: {
      fontSize: "1.125rem",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: 1.2,
    },
  },
  palette: {
    primary: {
      main: "#EDA65A",
    },
    secondary: {
      main: "#314962",
      contrastText: "#ffffff",
    },
    disabled: {
      main: "#494949",
      contrastText: "#8A8A8A",
    },
    text: {
      main: "#000000",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: FONT_FAMILY,
      },
      h1: {
        fontSize: "2rem",
      },
      h2: {
        fontSize: "1.5rem",
      },
      h3: {
        fontSize: "1.125rem",
      },
    },
  },
}) as ThemeOptions;

export const invertedTheme = createTheme({
  ...theme,
  palette: {
    secondary: {
      main: "#fff",
    },
  },
});
