import { usePageData } from "../usePageData";

export function useSectionData(pageHandle: string, sectionHandle: string) {
  const { page, error, isLoading } = usePageData(pageHandle);
  const sections = page?.sections || [];
  const section = sections.find(({ handle }) => handle === sectionHandle);

  return {
    section,
    error,
    isLoading,
  };
}
