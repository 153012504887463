import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { GradientBg } from "../../../../GradientBg";
import { ExpandableLinksBox } from "../../../../ExpandableLinksBox";
import { ContentWrapper } from "../../../../ContentWrapper";
import { Markdown } from "../../../../Markdown";
import { ContentRenderingComponents } from "../../../Page";
import { WithResolvedPictureURL } from "../../../Page/WithResolvedPictureURL";
import { WithWrappingBox } from "../../../../pages/Page/WithWrappingBox";
import { PlanningBox } from "./PlanningBox";
import { SignupBox } from "./SignupBox";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";

export const WithVerticalSpacing = (Component: React.FC<Content>) =>
  function RenderComponentWithVerticalSpacing(props: Content) {
    const WrappedComponent = WithWrappingBox({
      style: { marginBottom: "1.5rem" },
    })(Component);

    return <WrappedComponent {...props} />;
  };

const LinksBoxWithSpacing = WithVerticalSpacing(ExpandableLinksBox);

export const contents: ContentRenderingComponents = {
  "irc.hero.learn-more": ContentWithOnDemandTranslation(({ title, body }) => (
    <GradientBg kind="secondary">
      <ContentWrapper>
        <Box>
          <Grid alignItems="center" spacing={2} container>
            <Grid xs={12} md={10} item>
              <Typography sx={{ fontSize: "1.5rem" }}>{title}</Typography>
            </Grid>
            <Grid xs={12} md={2} item>
              <Markdown>{body}</Markdown>
            </Grid>
          </Grid>
        </Box>
      </ContentWrapper>
    </GradientBg>
  )),
  "irc.hero.video": ContentWithOnDemandTranslation(
    WithResolvedPictureURL(({ body, picture }) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundImage: `url(${picture})`,
          position: "relative",
          minHeight: { xs: "240px", sm: "360px", md: "400px" },
        }}
      >
        <Markdown>{body}</Markdown>
      </Box>
    ))
  ),
  "irc.planning-for-success.infrastructure-insite":
    ContentWithOnDemandTranslation(PlanningBox),
  "irc.planning-for-success.funding-finder":
    ContentWithOnDemandTranslation(PlanningBox),
  "irc.planning-for-success.total-cost":
    ContentWithOnDemandTranslation(PlanningBox),
  "irc.planning-for-success.low-carbon":
    ContentWithOnDemandTranslation(PlanningBox),
  "irc.workshops-and-webinars.signup":
    ContentWithOnDemandTranslation(SignupBox),
  "irc.resources.electric": ContentWithOnDemandTranslation(
    WithWrappingBox({ mt: 6 })(LinksBoxWithSpacing)
  ),
  "irc.resources.electric.es": ContentWithOnDemandTranslation(
    WithWrappingBox({ mt: 6 })(LinksBoxWithSpacing)
  ),
  "irc.resources.hydrogen": ContentWithOnDemandTranslation(LinksBoxWithSpacing),
  "irc.resources.hydrogen.es":
    ContentWithOnDemandTranslation(LinksBoxWithSpacing),
  "irc.resources.project-resources":
    ContentWithOnDemandTranslation(LinksBoxWithSpacing),
  "irc.resources.project-resources.es":
    ContentWithOnDemandTranslation(LinksBoxWithSpacing),

   "irc.resources.set-aside": ContentWithOnDemandTranslation(LinksBoxWithSpacing),
  //  "irc.resources.set-aside.es":  ContentWithOnDemandTranslation(LinksBoxWithSpacing),
};
