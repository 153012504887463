import "./styles.scss";
import React from "react";
import {
  Box,
  BoxProps,
  useMediaQuery,
  useTheme,
  ThemeProvider,
} from "@material-ui/core";
import { BoxGradientBg } from "../GradientBg";
import { ContentWrapper } from "../ContentWrapper";
import { theme } from "../../theme";
import { useBoundingClientRect } from "../../hooks/useBoundingClientRect";

export interface BannerSectionProps extends Partial<BoxProps> {
  img: string;
  alt: string;
}

export const BannerSection: React.FC<BannerSectionProps> = ({
  children,
  img,
  alt,
  className = "",
  ...props
}) => {
  const isLargeScreen = useMediaQuery(useTheme().breakpoints.up("lg"));
  const {
    rect: { height: rawHeight },
    ref,
  } = useBoundingClientRect();
  const height = Math.floor(rawHeight);
  const borderRadius = `${Math.ceil(height / 2)}px`;

  return (
    <ThemeProvider theme={theme}>
      <Box
        className={`BannerSection ${className} ${
          isLargeScreen ? "BannerSection--large" : "BannerSection--small"
        }`}
        {...props}
      >
        <div>
          <div
            className="BannerSection__img--container"
            style={{ height }}
            ref={ref}
          >
            <img
              className="BannerSection__img"
              src={img}
              alt={alt}
              style={{ height }}
            />
          </div>
          <BoxGradientBg
            style={
              isLargeScreen
                ? {
                    borderTopLeftRadius: borderRadius,
                    borderBottomLeftRadius: borderRadius,
                  }
                : {}
            }
            className="BannerSection__content"
            kind="secondary"
          >
            {isLargeScreen ? (
              children
            ) : (
              <ContentWrapper>{children}</ContentWrapper>
            )}
          </BoxGradientBg>
        </div>
      </Box>
    </ThemeProvider>
  );
};

BannerSection.displayName = "BannerSection";
