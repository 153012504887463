/* eslint-disable jsx-a11y/alt-text */
import "./styles.scss";
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Electric_Vehicles from "../../assets/EnergiizeAssets/whiteBuses.jpeg";
import H2_Bus from "../../assets/EnergiizeAssets/Asset5_H2_Bus.jpeg";
import Offroad from "../../assets/EnergiizeAssets/Asset1_MainBannerOffroad.jpeg";
import Girl_with_curly_hair from "../../assets/EnergiizeAssets/Asset2_MainBanner_RidingBus.jpeg";
import The_faces from "../../assets/EnergiizeAssets/Asset4_MainBanner_Community.jpg";
import { useWindowResize } from "../../hooks";

export interface ImagesCubeProps {}

const cycles = [
  [Electric_Vehicles, H2_Bus, Offroad, The_faces],
  [Girl_with_curly_hair, H2_Bus, Offroad,The_faces],
];

const maxWidth = 960;

const TIMING = 5000;

export const ImagesCube: React.FC<ImagesCubeProps> = () => {
  const ref = useRef(null);
  const [width, setWidth] = useState(800);
  const [started, setStarted] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);
  const [position, setPosition] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [shouldChangeCycle, setShouldChangeCycle] = useState(false);
  const [currentCycle, setCurrentCycle] = useState(0);
  const [faces, setFaces] = useState(cycles[0]);
  const [loopCount, setLoopCount] = useState(0);
  const style = {
    transform: `translateZ(-${width / 2}px) rotateY(${rotation}deg)`,
  };

  const handleResizing = () => {
    if (!ref?.current || !(ref?.current as HTMLElement)?.parentNode) {
      return;
    }

    const parentNode = (ref.current as HTMLElement).parentNode as HTMLElement;
    const contentWrapperBoundary = document.querySelector(
      ".ContentWrapper__boundary"
    ) as HTMLElement;
    const computedStyles = getComputedStyle(contentWrapperBoundary);
    const paddingLeft = parseInt(
      computedStyles.getPropertyValue("padding-left"),
      10
    );
    const windowWidth = document.body.offsetWidth;
    const marginDifference = (windowWidth * 20) / 100;
    const parentWidth = parentNode.getBoundingClientRect().width;
    const wrapperWidth = parentWidth < maxWidth ? parentWidth : maxWidth;
    const width =
      Math.floor(wrapperWidth) -
      parseInt(`${paddingLeft > 0 ? paddingLeft : marginDifference / 2}`, 10);

    setWidth(width);
  };

  useLayoutEffect(() => {
    handleResizing();
  }, []);

  useEffect(() => {
    if (!isFrozen) {
      return;
    }

    setRotation(0);
  }, [isFrozen]);

  useEffect(() => {
    if (started && position === 0) {
      setIsFrozen(true);
    } else {
      setIsFrozen(false);
    }
  }, [started, position]);

  useEffect(() => {
    setShouldChangeCycle(position === 1);
  }, [position]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newPosition = position + 1 < 5 ? position + 1 : 0;
      const newRotation = rotation - 90;
      setPosition(newPosition);
      setRotation(newRotation);
      setStarted(true);
      clearTimeout(timeout);

      if (newPosition === 0) {
        setLoopCount(loopCount + 1);
      }

      if (shouldChangeCycle) {
        const newCurrentCycle = currentCycle === 0 ? 1 : 0;

        setCurrentCycle(newCurrentCycle);
        setFaces(cycles[newCurrentCycle]);
      }
    }, TIMING);

    return () => clearTimeout(timeout);
  }, [position, rotation, loopCount, currentCycle, shouldChangeCycle]);

  useWindowResize(handleResizing);

  return (
    <div
      ref={ref}
      className="ImagesCube__scene"
      style={{
        width: `${width}px`,
        height: `${width / 2}px`,
        perspective: `${width * 2}px`,
      }}
    >
      <div
        className="ImagesCube__cube"
        data-is-frozen={isFrozen}
        data-position={position}
        style={style}
      >
        {faces.map((src, index) => {
          const style =
            index === 0
              ? { transform: `rotateY(0deg) translateZ(${width / 2}px)` }
              : index === 1
              ? { transform: `rotateY(90deg) translateZ(${width / 2}px)` }
              : index === 2
              ? { transform: `rotateY(180deg) translateZ(${width / 2}px)` }
              : { transform: `rotateY(-90deg) translateZ(${width / 2}px)` };

          return (
            <div
              className="ImagesCube__face"
              style={{
                ...style,
                width: `${width}px`,
                height: `${width / 2}px`,
              }}
              data-is-current={
                index === position || (index === 0 && position === 4)
              }
              key={index}
            >
              <img src={src} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ImagesCube.displayName = "ImagesCube";
