import React from "react";
import { useMarkdownLinkBlock } from "../../../../hooks";
import { Content } from "../../../../models";

export const WithExtractedMarkdownLinkBlock = (Component: React.FC<Content>) =>
  function RenderComponentWithExtractedLink({
    body: originalBody,
    ...props
  }: Content) {
    const { link, body } = useMarkdownLinkBlock(originalBody);

    return <Component {...props} link={link.trim()} body={body} />;
  };
