import React from "react";
import { Grid, Typography, ThemeProvider, Box } from "@material-ui/core";
import { theme } from "../../theme";

export interface TextCtaProps {
  text: string;
  cta: React.ReactNode;
}

export const TextCta: React.FC<TextCtaProps> = ({ text, cta }) => {
  if (!text) {
    throw new Error("The `text` prop is required.");
  }

  if (!cta) {
    throw new Error("The `cta` prop is required.");
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container space={2} alignItems="center">
        <Grid item xs={12} md={8}>
          <Typography variant="h1" component="span" color="secondary">
            {text}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mt={{ xs: 2, sm: 2, md: 0 }}>{cta}</Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

TextCta.displayName = "TextCta";
