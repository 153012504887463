import "./styles.scss";
import React from "react";
import { ThemeProvider, Box, Typography } from "@material-ui/core";
import { AnimatedSectionTitle } from "../AnimatedSectionTitle";
import { theme } from "../../theme";

export interface TitleImageBulletsProps {
  title: string;
  imageURL: string;
  items: string[] | React.ReactNode[];
  alt?: string;
}

export const TitleImageBullets: React.FC<TitleImageBulletsProps> = ({
  title,
  imageURL,
  items,
  alt,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <AnimatedSectionTitle>{title}</AnimatedSectionTitle>
      <Box className="TitleImageBullets__image-container" mt={5}>
        <img
          className="TitleImageBullets__image"
          src={imageURL}
          alt={alt || title}
        />
      </Box>
      <Box component="ul" mt={5}>
        {items.map((item, index) => (
          <Typography component="li" key={`TitleImageBullets-item-${index}`}>
            {item}
          </Typography>
        ))}
      </Box>
    </ThemeProvider>
  );
};

TitleImageBullets.displayName = "TitleImageBullets";

export const TitleImageContent: React.FC<Omit<TitleImageBulletsProps, "items">> = ({
  title,
  imageURL,
  children,
  alt,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <AnimatedSectionTitle>{title}</AnimatedSectionTitle>
      <Box className="TitleImageBullets__image-container" mt={5}>
        <img
          className="TitleImageBullets__image"
          src={imageURL}
          alt={alt || title}
        />
      </Box>
      <Box mt={5}>
        {children}
      </Box>
    </ThemeProvider>
  );
};

TitleImageContent.displayName = "TitleImageContent";
