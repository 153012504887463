import React from "react";
import { Grid } from "@material-ui/core";
import { HorizontalStep, HorizontalStepProps } from "./HorizontalStep";

export interface HorizontalStepsProps {
  steps: Array<Pick<HorizontalStepProps, "content">>;
}

export const HorizontalSteps: React.FC<HorizontalStepsProps> = ({
  steps = [],
}) => {
  return (
    <Grid spacing={2} container>
      {steps.map(({ content }, index) => (
        <Grid item xs={12} md={3} key={`horizontal-step-${index}-${content}`}>
          <HorizontalStep content={content} step={index + 1} />
        </Grid>
      ))}
    </Grid>
  );
};

HorizontalSteps.displayName = "HorizontalSteps";
