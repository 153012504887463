import { createContext, useState } from "react";
import { Languages } from "../constants";

const initialValue = {
  language: Languages.EN,
  setLanguage: (language: Languages) => null,
};

export const LanguagesContext = createContext(initialValue);

export const LanguagesProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState(Languages.EN);
  const value = { language, setLanguage };

  return (
    <LanguagesContext.Provider value={value}>
      {children}
    </LanguagesContext.Provider>
  );
};
