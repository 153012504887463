import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import ComplexCardInteraction from "../components/cards/ComplexCardInteraction";
import hands from "../assets/EnergiizeAssets/hands.jpeg";
import _EVFastTrack from "../assets/EnergiizeAssets/_EVFastTrack.jpg";
import _PublicChargingLane from "../assets/EnergiizeAssets/_PublicChargingLane.jpeg";
import _HydrogenLane from "../assets/EnergiizeAssets/13_HydrogenLane.jpeg";
import hydrogenLane from "../assets/EnergiizeAssets/downloads/funding/4. Hydrogen Lane Factsheet.pdf";
import publicCharging from "../assets/EnergiizeAssets/downloads/funding/5. EV Public Charging Lane Factsheet.pdf";
import fastTrack from "../assets/EnergiizeAssets/downloads/funding/EV Fast Track Lane Factsheet.pdf";
import Jumpstart from "../assets/EnergiizeAssets/downloads/funding/EV Jump Start Lane Factsheet.pdf";
import "../scss/main.scss";
import { ParagraphTextWithAnimatedTitle } from "../components/ParagraphTextWithAnimatedTitle";
import { ContentWrapper } from "../components/ContentWrapper";
import { theme, invertedTheme } from "../theme";

const ContentBoundary = ({ className, children }) => (
  <Grid className={className} container>
    {children}
  </Grid>
);

const Wrapper = ({ children, ...props }) => (
  <ContentWrapper boundaryComponent={ContentBoundary}>
    <Grid {...props}>{children}</Grid>
  </ContentWrapper>
);

export const SubscribeSection = ({ title, cta, invert = false }) => {
  const isMediumScreen = useMediaQuery(useTheme().breakpoints.up("md"));
  const margin = isMediumScreen ? { marginLeft: 4 } : { marginTop: 4 };

  return (
    <ThemeProvider theme={invert ? invertedTheme : theme}>
      <Box
        className="SubscribeSection"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign={isMediumScreen ? "left" : "center"}
        flexDirection={isMediumScreen ? "row" : "column"}
      >
        <Typography variant="h2" component="span" color="secondary">
          {title}
        </Typography>
        <Box {...margin}>{cta}</Box>
      </Box>
    </ThemeProvider>
  );
};

SubscribeSection.displayName = "SubscribeSection";

export class _ParticipateBuilder {
  constructor() {
    this.inputs = {
      fundingTitle: "",
      fundingParagraph: "",
      fundingFastTrack: {},
      fundingJumpStart: {},
      fundingPublicCharging: {},
      fundingHydrogen: {},
      fundingSubscribe: {},
    };

    //dynamically generating setters for inputs in constructor
    Object.keys(this.inputs).forEach((input) => {
      // if booleanValue, it will have the property name became has
      let functionNameValue =
        typeof this.inputs[input] == "boolean"
          ? `has${input.charAt(0).toUpperCase() + input.slice(1)}`
          : `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

      this[`${functionNameValue}`] = (value) => {
        this.inputs[`${input}`] = value;
        return this;
      };
    });
  }

  build() {
    return {
      sections: [
        {
          uiComponent: Grid,
          props: {
            id: "FundingSection",
            container: true,
          },
          sections: [
            {
              uiComponent: Wrapper,
              props: {
                id: "EnergiizePickFunding",
                container: true,
                spacing: 5,
              },
              elements: [
                {
                  gridItemProps: {
                    item: true,
                    xs: 12,
                  },
                  uiComponent: ParagraphTextWithAnimatedTitle,
                  props: {
                    title: this.inputs.fundingTitle,
                    children: this.inputs.fundingParagraph,
                  },
                },
                {
                  gridItemProps: {
                    item: true,
                    xs: 12,
                    md: 6,
                  },
                  uiComponent: ComplexCardInteraction,
                  props: {
                    className: "page-sub-header",
                    img: _EVFastTrack,
                    altText: this.inputs.fundingFastTrack.title,
                    hiddenText: (
                      <div className="complex_text_container">
                        {this.inputs.fundingFastTrack.body}
                        <a href={fastTrack} id="downloadFactsheet" download>
                          {this.inputs.fundingFastTrack.download}
                        </a>
                      </div>
                    ),
                  },
                },
                {
                  gridItemProps: {
                    item: true,
                    xs: 12,
                    md: 6,
                  },
                  uiComponent: ComplexCardInteraction,
                  props: {
                    className: "page-sub-header",
                    img: hands,
                    altText: this.inputs.fundingJumpStart.title,
                    hiddenText: (
                      <div className="complex_text_container">
                        {this.inputs.fundingJumpStart.body}
                        <a href={Jumpstart} id="downloadFactsheet" download>
                          {this.inputs.fundingJumpStart.download}
                        </a>
                      </div>
                    ),
                  },
                },
                {
                  gridItemProps: {
                    item: true,
                    xs: 12,
                    md: 6,
                  },
                  uiComponent: ComplexCardInteraction,
                  props: {
                    className: "page-sub-header",
                    img: _PublicChargingLane,
                    altText: this.inputs.fundingPublicCharging.title,
                    hiddenText: (
                      <div className="complex_text_container">
                        {this.inputs.fundingPublicCharging.body}
                        <a
                          href={publicCharging}
                          id="downloadFactsheet"
                          download
                        >
                          {this.inputs.fundingPublicCharging.download}
                        </a>
                      </div>
                    ),
                  },
                },
                {
                  gridItemProps: {
                    item: true,
                    xs: 12,
                    md: 6,
                  },
                  uiComponent: ComplexCardInteraction,
                  props: {
                    className: "page-sub-header",
                    img: _HydrogenLane,
                    altText: this.inputs.fundingHydrogen.title,
                    hiddenText: (
                      <div className="complex_text_container">
                        {this.inputs.fundingHydrogen.body}
                        <a href={hydrogenLane} id="downloadFactsheet" download>
                          {this.inputs.fundingHydrogen.download}
                        </a>
                      </div>
                    ),
                  },
                },
              ],
            },
            {
              uiComponent: Wrapper,
              props: {
                className: "subscribe_container",
                container: true,
                item: true,
                md: 12,
              },
              elements: [
                {
                  gridItemProps: {
                    item: true,
                    sm: 12,
                  },
                  uiComponent: () => (
                    <SubscribeSection
                      title={this.inputs.fundingSubscribe.title}
                      cta={this.inputs.fundingSubscribe.download}
                    />
                  ),
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
const ParticipateSectionMetadata = new _ParticipateBuilder();

export default ParticipateSectionMetadata;
