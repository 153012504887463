import React from "react";
import { Grid, Box } from "@material-ui/core";
import { ContentWrapper } from "../../../../ContentWrapper";
import { SmoothBg } from "../../../../SmoothBg";
import { PartnerTile } from "../../../../our-partners/PartnerTile";
import { CorePrinciplesSection } from "../../../../core-principles/CorePrinciplesSection";
import {
  FUNDING,
  ENERGY,
  DIVERSITY,
  TRANSPARENCY,
  COMMUNITY,
  POLICY,
} from "../../../../core-principles/CorePrinciplesPieChart";
import { MarkdownParagraph, MarkdownWithMainTitle } from "../../../../Markdown";
import {
  SectionRenderingComponents,
  ContentComponent,
  ContentContainer,
  contentsAsObject,
  sort,
} from "../../../Page";
import { WithResolvedPictureURL } from "../../../Page/WithResolvedPictureURL";
import { Content } from "../../../../../models";
import { contents } from "../contents";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";

const components = { contents };

export function useCorePrinciplesChartData(contents: Array<Content>) {
  const map = contentsAsObject(contents);
  const intro = map["about.our-core-principles.intro"];
  const funding = map["about.our-core-principles.funding"];
  const energy = map["about.our-core-principles.energy"];
  const diversity = map["about.our-core-principles.diversity"];
  const transparency = map["about.our-core-principles.transparency"];
  const community = map["about.our-core-principles.community"];
  const policy = map["about.our-core-principles.policy"];
  const content = {
    [TRANSPARENCY]: { title: transparency.title, content: transparency.body },
    [COMMUNITY]: { title: community.title, content: community.body },
    [ENERGY]: { title: energy.title, content: energy.body },
    [POLICY]: { title: policy.title, content: policy.body },
    [FUNDING]: { title: funding.title, content: funding.body },
    [DIVERSITY]: { title: diversity.title, content: diversity.body },
  };

  return { intro, content };
}

const MarkdownWithMainTitleWithOnDemandTranslation =
  ContentWithOnDemandTranslation(MarkdownWithMainTitle);

export const AboutOurCorePrinciples: React.FC<{ contents: Array<Content> }> = ({
  contents,
}) => {
  const { intro, content } = useCorePrinciplesChartData(contents);

  return (
    <ContentWrapper>
      <MarkdownWithMainTitleWithOnDemandTranslation
        title={intro.title}
        body={intro.body}
      />
      <Box mt={4}>
        <CorePrinciplesSection content={content} />
      </Box>
    </ContentWrapper>
  );
};

export const AboutOurPartnersContent = ContentWithOnDemandTranslation(
  WithResolvedPictureURL(({ id, handle, title, body, picture, zIndex }) => {
    return (
      <Grid
        key={id}
        item
        xs={12}
        md={["about.our-partners.intro"].includes(handle) ? 12 : 3}
        data-model="content"
        data-id={id}
        data-handle={handle}
        style={{ zIndex }}
      >
        <ContentContainer key={id} id={id} handle={handle}>
          {["about.our-partners.intro"].includes(handle) ? (
            <ContentComponent
              id={id}
              handle={handle}
              title={title}
              body={body}
              picture={picture}
              components={components}
            />
          ) : (
            <PartnerTile
              name={title}
              text={<MarkdownParagraph body={body} />}
              imgURL={picture}
              zIndex={zIndex}
            />
          )}
        </ContentContainer>
      </Grid>
    );
  })
);

export const AboutOurPartners: React.FC<{ contents: Array<Content> }> = ({
  contents,
}) => {
  return (
    <SmoothBg variant="green">
      <ContentWrapper>
        <Box>
          <Grid spacing={5} container>
            {sort(contents).map((props, index) => (
              <AboutOurPartnersContent
                {...props}
                key={props.id}
                zIndex={contents.length - index}
              />
            ))}
          </Grid>
        </Box>
      </ContentWrapper>
    </SmoothBg>
  );
};

export const sections: SectionRenderingComponents = {
  "about.our-story": ({ contents }) => (
    <>
      {sort(contents).map(({ id, handle, title, body, picture }) => {
        return (
          <ContentContainer key={id} id={id} handle={handle}>
            <ContentComponent
              id={id}
              handle={handle}
              title={title}
              body={body}
              picture={picture}
              components={components}
            />
          </ContentContainer>
        );
      })}
    </>
  ),
  "about.our-core-principles": AboutOurCorePrinciples,
  "about.our-partners": AboutOurPartners,
};
