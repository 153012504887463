import {
  MarkdownBanner,
  MarkdownFramedImageFlag,
  ReversedMarkdownFramedImageFlag,
  MarkdownParagraph,
  MarkdownWithMainTitle,
} from "../../../../Markdown";
import { ContentRenderingComponents } from "../../../Page";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";

export const contents: ContentRenderingComponents = {
  "vendor.info": ContentWithOnDemandTranslation(MarkdownWithMainTitle),
  "vendor.vendor-types.approved": ContentWithOnDemandTranslation(
    MarkdownFramedImageFlag
  ),
  "vendor.vendor-types.preferred": ContentWithOnDemandTranslation(
    ReversedMarkdownFramedImageFlag
  ),
  "vendor.find.info": ContentWithOnDemandTranslation(MarkdownBanner),
  "vendor.want-to.title": ContentWithOnDemandTranslation(MarkdownWithMainTitle),
  "vendor.want-to.approved": ContentWithOnDemandTranslation(MarkdownParagraph),
  "vendor.want-to.preferred": ContentWithOnDemandTranslation(MarkdownParagraph),
  "vendor.want-to.application-packet":
    ContentWithOnDemandTranslation(MarkdownParagraph),
};
