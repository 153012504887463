import React, { Children, ReactChild, ElementType } from "react";
import { Grid, GridProps, Theme, useMediaQuery } from "@material-ui/core";
import { theme } from "../../theme";

export interface FlagProps extends Partial<GridProps> {
  proportion?: [number, number];
  reverse?: boolean;
  keepProportionWhenReversed?: boolean;
  component?: ElementType<any>;
  resolveShouldReverseOrder?: (reverse: boolean) => boolean;
}

export const useDefaultShouldReverseOrderResolver = (reverse: boolean) => {
  const isBigEnoughScreen = useMediaQuery(
    (theme as Theme).breakpoints.up("md")
  );

  return reverse && isBigEnoughScreen;
};

export const Flag: React.FC<FlagProps> = ({
  children,
  proportion = [4, 8],
  reverse = false,
  keepProportionWhenReversed = true,
  component = "div",
  resolveShouldReverseOrder = useDefaultShouldReverseOrderResolver,
  ...props
}) => {
  const validChildrenArray = Children.toArray(children).filter(
    React.isValidElement
  );
  const shouldReverseOrder = resolveShouldReverseOrder(reverse);
  const left =
    shouldReverseOrder && !keepProportionWhenReversed
      ? proportion[1]
      : proportion[0];
  const right =
    shouldReverseOrder && !keepProportionWhenReversed
      ? proportion[0]
      : proportion[1];

  if (validChildrenArray.length !== 2) {
    throw new Error(
      "The Flag component can only take 2 elements as its children"
    );
  }

  return (
    <Grid
      container
      style={{ flexDirection: shouldReverseOrder ? "row-reverse" : undefined }}
      justifyContent={shouldReverseOrder ? "flex-end" : "flex-start"}
      component={component}
      data-reversed={shouldReverseOrder}
      {...props}
    >
      {Children.map(validChildrenArray, (child: ReactChild, index) => (
        <Grid item xs={12} md={index === 0 ? left : right}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

Flag.displayName = "Flag";
