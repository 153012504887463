import React from 'react';
import { Card, CardMedia, CardContent, Collapse, IconButton, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../../scss/main.scss"

export default function ComplexCardInteraction({ img, altText, hiddenText, title = '' }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card >
            <CardMedia
                className="card-image"
                image={img}
                title={title}
            />
            <CardContent className="card_content_container">
                <Typography className="card_title">
                    {altText}
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon style={{color: "white"}}/>
                    </IconButton>
                </Typography>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className="card_content_container">
                    <Typography paragraph> {hiddenText}</Typography>

                </CardContent>
            </Collapse>
        </Card>
    );
}