import React from "react";
import { AppButton, AppButtonProps } from "../AppButton";
import { useAmplifyFileUrl } from "../../hooks";

export interface DownloadButtonProps
  extends Partial<Pick<AppButtonProps, "variant">> {
  file: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  children,
  file,
  variant,
}) => {
  const { url } = useAmplifyFileUrl(file);

  return url ? (
    <AppButton
      component="a"
      href={url}
      variant={variant}
      target="_blank"
      rel="noopener noreferrer"
      download
    >
      {children}
    </AppButton>
  ) : null;
};

DownloadButton.displayName = "DownloadButton";
