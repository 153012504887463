import React from "react";
import { CorePrinciplesIconProps } from "./CorePrinciplesIcon";

export const PolicyIcon: React.FC<Pick<CorePrinciplesIconProps, "fill">> = ({
  fill = "#314962",
}) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.836 32.535C30.8613 32.0101 30.3671 31.2411 30.3671 30.2497V7.93259C30.3671 7.65738 30.144 7.43438 29.8689 7.43438C29.5937 7.43438 29.3707 7.65738 29.3707 7.93259V30.2497C29.3707 31.3748 29.8375 32.3147 30.7297 33.0036H10.4599C8.90491 33.0036 7.6398 31.7385 7.6398 30.1835C7.6398 30.1835 7.6398 2.94301 7.6398 2.94294C7.6398 2.22513 7.37774 1.53435 6.90232 0.996415H26.5506C28.1056 0.996415 29.3707 2.26153 29.3707 3.81653V5.6409C29.3707 5.91611 29.5937 6.13911 29.8689 6.13911C30.144 6.13911 30.3671 5.91611 30.3671 5.6409V3.81653C30.3671 1.71204 28.655 0 26.5506 0H4.69685C3.07409 0 1.75391 1.32018 1.75391 2.94294V4.76486C1.75391 5.38303 2.25676 5.88589 2.87487 5.88589H6.64338V30.1834C6.64338 32.2879 8.35549 33.9999 10.4599 33.9999H31.4699C31.8324 33.9999 32.1353 33.7643 32.2238 33.4135C32.3128 33.0609 32.1569 32.7079 31.836 32.535ZM6.64338 4.88947H2.87487C2.80619 4.88947 2.75032 4.83354 2.75032 4.76486V2.94294C2.75032 1.86967 3.62351 0.996415 4.69685 0.996415C5.77019 0.996415 6.64338 1.86967 6.64338 2.94294V4.88947Z"
      fill={fill}
    />
    <path
      d="M22.9493 4.94039C23.2245 4.94039 23.4475 4.71739 23.4475 4.44218C23.4475 4.16697 23.2245 3.94397 22.9493 3.94397H14.0607C13.7856 3.94397 13.5625 4.16697 13.5625 4.44218C13.5625 4.71739 13.7856 4.94039 14.0607 4.94039H22.9493Z"
      fill={fill}
    />
    <path
      d="M26.2923 8.45459H10.7189C10.4438 8.45459 10.2207 8.67759 10.2207 8.9528C10.2207 9.22801 10.4438 9.451 10.7189 9.451H26.2923C26.5674 9.451 26.7905 9.22801 26.7905 8.9528C26.7905 8.67759 26.5674 8.45459 26.2923 8.45459Z"
      fill={fill}
    />
    <path
      d="M26.2923 10.9332H10.7189C10.4438 10.9332 10.2207 11.1562 10.2207 11.4314C10.2207 11.7066 10.4438 11.9296 10.7189 11.9296H26.2923C26.5674 11.9296 26.7905 11.7066 26.7905 11.4314C26.7905 11.1562 26.5674 10.9332 26.2923 10.9332Z"
      fill={fill}
    />
    <path
      d="M26.7905 13.9101C26.7905 13.6349 26.5674 13.4119 26.2923 13.4119H10.7189C10.4438 13.4119 10.2207 13.6349 10.2207 13.9101C10.2207 14.1853 10.4438 14.4083 10.7189 14.4083H26.2923C26.5674 14.4083 26.7905 14.1853 26.7905 13.9101Z"
      fill={fill}
    />
    <path
      d="M20.1791 16.3886C20.1791 16.1134 19.9561 15.8904 19.6809 15.8904H10.7189C10.4438 15.8904 10.2207 16.1134 10.2207 16.3886C10.2207 16.6638 10.4438 16.8868 10.7189 16.8868H19.6809C19.9561 16.8868 20.1791 16.6637 20.1791 16.3886Z"
      fill={fill}
    />
    <path
      d="M16.505 29.9595C16.7025 30.0767 16.9505 30.0808 17.1521 29.9704L18.5185 29.2209L19.8829 29.9744C19.9807 30.0284 20.0895 30.0553 20.1982 30.0553C20.3132 30.0553 20.4281 30.0252 20.5299 29.9653C20.7278 29.8487 20.851 29.6335 20.8512 29.4043L20.8597 25.7784C21.7267 25.0856 22.2838 24.0202 22.2838 22.8267C22.2838 20.7437 20.5892 19.0491 18.5061 19.0491C16.4231 19.0491 14.7285 20.7437 14.7285 22.8267C14.7285 23.1019 14.9516 23.3249 15.2267 23.3249C15.5019 23.3249 15.7249 23.1019 15.7249 22.8267C15.7249 21.2931 16.9726 20.0455 18.5061 20.0455C20.0397 20.0455 21.2874 21.2931 21.2874 22.8267C21.2874 24.3602 20.0397 25.6079 18.5061 25.6079C17.6857 25.6079 16.9111 25.2481 16.3809 24.6208C16.2033 24.4108 15.8889 24.3843 15.6788 24.562C15.4687 24.7396 15.4423 25.0539 15.6199 25.264C15.793 25.4688 15.9857 25.652 16.1934 25.8131L16.1851 29.397C16.1849 29.6267 16.3074 29.8422 16.505 29.9595ZM17.1887 26.3661C17.6052 26.5211 18.0502 26.6042 18.5062 26.6042C18.9839 26.6042 19.4408 26.5141 19.8619 26.3516L19.8562 28.8214L18.8347 28.2572C18.7359 28.2027 18.6276 28.1754 18.5191 28.1754C18.4113 28.1754 18.3035 28.2024 18.2051 28.2563L17.183 28.817L17.1887 26.3661Z"
      fill={fill}
    />
  </svg>
);

PolicyIcon.displayName = "PolicyIcon";
