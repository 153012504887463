import React from "react";
import { Box } from "@material-ui/core";
import {
  ParagraphTextWithAnimatedTitle,
  ParagraphTextWithAnimatedTitleProps,
} from "../ParagraphTextWithAnimatedTitle";
import { Flag, FlagProps } from "../Flag";

export interface TitleParagraphCtaProps
  extends ParagraphTextWithAnimatedTitleProps,
    Pick<FlagProps, "proportion"> {
  button: React.ReactNode;
}

export const TitleParagraphCta: React.FC<TitleParagraphCtaProps> = ({
  title,
  button,
  children,
  variant,
  proportion = [9, 3],
}) => {
  return (
    <ParagraphTextWithAnimatedTitle
      title={title}
      components={{ paragraph: "div" }}
      variant={variant}
    >
      <Flag
        proportion={proportion}
        component="div"
      >
        <Box mb={2}>{children}</Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {button}
        </Box>
      </Flag>
    </ParagraphTextWithAnimatedTitle>
  );
};

TitleParagraphCta.displayName = "TitleParagraphCta";
