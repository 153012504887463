import "./styles.scss";
import React, { useState, useRef, useLayoutEffect } from "react";
import { Markdown } from "../Markdown";
import { useBoundingClientRect } from "../../hooks";

export interface ExpandableLinksBoxProps {
  title: string;
  body: string;
}

const TIMEOUT = 1000;

export const ExpandableLinksBox: React.FC<ExpandableLinksBoxProps> = ({
  title,
  body,
}) => {
  const { ref, rect } = useBoundingClientRect();
  const contentRef = useRef();
  const [contentRect, setContentRect] = useState({ height: 0 });
  const [open, setOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const handleClick = () => setOpen((open) => !open);
  const style = open
    ? { height: `${Math.ceil(contentRect.height + rect.height)}px` }
    : {
        height: `${Math.ceil(rect.height)}px`,
      };

  useLayoutEffect(() => {
    if (
      !contentRef?.current ||
      (contentRef.current as HTMLElement).getBoundingClientRect().height === 0
    ) {
      return;
    }

    const timeout = setTimeout(() => {
      setContentRect(
        (contentRef.current as unknown as HTMLElement).getBoundingClientRect()
      );
      setReady(true);
      clearTimeout(timeout);
    }, TIMEOUT);

    return () => clearTimeout(timeout);
  }, [contentRef]);

  return (
    <div
      className="ExpandableLinksBox"
      style={contentRef?.current && ref?.current ? style : {}}
      data-ready={ready}
    >
      <button
        ref={ref}
        className="ExpandableLinksBox__header"
        onClick={handleClick}
      >
        <h2 className="ExpandableLinksBox__title">{title}</h2>
        <span className="ExpandableLinksBox__icon" data-open={open}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8.5L6 14.5L7.41 15.91L12 11.33L16.59 15.91L18 14.5L12 8.5Z"
              fill="black"
              fill-opacity="0.54"
            />
          </svg>
        </span>
      </button>
      <div
        ref={contentRef}
        className="ExpandableLinksBox__body"
        data-open={open}
      >
        <Markdown>{body}</Markdown>
      </div>
    </div>
  );
};

ExpandableLinksBox.displayName = "ExpandableLinksBox";
