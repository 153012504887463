import { Languages } from "./languages";

export const copy = {
  [Languages.EN]: {
    /** Custom **/
    "Tetra Tech Inc.": "Tetra Tech Inc.",
    "GRID Alternatives": "GRID Alternatives",
    /** Generic **/
    learn_more: "Learn More",
    step: "Step",
    watch: "Watch",
    /** Footer **/
    footer_contactUs: "Contact Us",
    footer_getInContact: "Get in contact and learn about",
    footer_aboutTheLatestNews: "the latest EnergIIZE News",
    footer_subscribe: "Subscribe",
    footer_notFindingWhatYouNeed: "Not finding what you need?",
    footer_notFindingWhatYouNeedLink: "Click here",
    footer_notFindingWhatYouNeedLinkSurvey:
      "to be redirected to a short survey",
    footer_poweredBy: "Powered By",
    /** Menu **/
    menu_home: "Home",
    menu_about: "About",
    menu_infrastructure: "Infrastructure",
    menu_vendor: "Partners",
    menu_participate: "Participate",
    menu_irc: "Resources",
    menu_news: "Updates",
    menu_contact: "Contact",
    menu_ipc: "IPC",
    /**  Sub Menus **/
    sub_menu_about_story: "Story",
    sub_menu_about_principles: "Principles",
    sub_menu_about_partners: "Partners",
    sub_menu_about_committees: "Committees",
    sub_menu_infrastructure_tech: "Technology Catalog",
    sub_menu_infrastructure_ready: "Make Ready",
    sub_menu_vendor_approved: "Application Partners",
    sub_menu_vendor_preferred: "Installation Partners",
    sub_menu_vendor_find: "Find a Partner",
    sub_menu_vendor_become: "Become a Partner",
    sub_menu_participate_fleet: "Commercial Fleet",
    sub_menu_participate_process: "Funding Process",
    sub_menu_participate_lane: "Funding Lane",
    sub_menu_irc_planning: "Planning",
    sub_menu_irc_events: "Events",
    sub_menu_irc_resources: "Resources",
  },
  [Languages.ES]: {
    /** Custom **/
    "tetratech inc.": "Tetra Tech Inc.",
    "Alternativas a la red": "Alternativas a la Red",
    /** Generic **/
    learn_more: "Aprende más",
    step: "Paso",
    watch: "Ver",
    /** Footer **/
    footer_aboutTheLatestNews: "las últimas noticias de EnergIIZE",
    footer_contactUs: "Contáctenos",
    footer_getInContact: "Ponte en contacto e infórmate",
    footer_notFindingWhatYouNeed: "¿No encuentra lo que necesita?",
    footer_notFindingWhatYouNeedLink: "haga clic aquí",
    footer_notFindingWhatYouNeedLinkSurvey:
      "para ser redirigido a una breve encuesta",
    footer_poweredBy: "Energizado por",
    footer_subscribe: "Suscribir",
    /** Menu **/
    menu_home: "Casa",
    menu_about: "Sobre",
    menu_infrastructure: "Infraestructura",
    menu_vendor: "Vendedores",
    menu_participate: "Participar",
    menu_irc: "Recursos",
    menu_news: "Actualizaciones",
    menu_contact: "Contacto",
    menu_ipc: "IPC",
    /**  Sub Menus **/
    sub_menu_about_story: "Historia",
    sub_menu_about_principles: "Principios",
    sub_menu_about_partners: "Socios",
    sub_menu_about_committees: "Comités",
    sub_menu_infrastructure_tech: "Catálogo de Tecnología",
    sub_menu_infrastructure_ready: "Preparar",
    sub_menu_vendor_approved: "Proveedores aprobados",
    sub_menu_vendor_preferred: "Proveedores preferidos",
    sub_menu_vendor_find: "Encontrar un proveedor",
    sub_menu_vendor_become: "Conviértete en un vendedor",
    sub_menu_participate_fleet: "Flota Comercial",
    sub_menu_participate_process: "Proceso de Financiamiento",
    sub_menu_participate_lane: "Carril de financiación",
    sub_menu_irc_planning: "Planificación",
    sub_menu_irc_events: "Eventos",
    sub_menu_irc_resources: "Recursos",
  },
};
