import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import {
  Typography,
  Box,
  TypographyProps,
  ThemeProvider,
} from "@material-ui/core";
import { theme } from "../../theme";

export interface BaseSectionTitleProps extends Partial<TypographyProps> {}

export const BaseSectionTitle: ForwardRefExoticComponent<
  BaseSectionTitleProps & RefAttributes<HTMLElement>
> = forwardRef(
  (
    {
      children,
      className,
      variant = "h2",
      color = "secondary",
      style,
      ...typographyProps
    },
    ref
  ) => {
    return (
      <ThemeProvider theme={theme}>
        <Box mb={2}>
          <Typography
            variant={variant}
            className={className}
            ref={ref}
            color={color}
            style={{ display: "inline-block", ...style }}
            {...typographyProps}
          >
            {children}
          </Typography>
        </Box>
      </ThemeProvider>
    );
  }
);

BaseSectionTitle.displayName = "BaseSectionTitle";

export interface SectionTitleProps extends BaseSectionTitle {
  kind: "default" | "main";
}

export const DefaultTitle: ForwardRefExoticComponent<
  SectionTitleProps & RefAttributes<HTMLElement>
> = forwardRef(
  (
    {
      children,
      className,
      variant = "h2",
      color = "secondary",
      ...typographyProps
    },
    ref
  ) => {
    return (
      <BaseSectionTitle
        ref={ref}
        className={className}
        variant={variant}
        color={color}
        {...typographyProps}
      >
        {children}
      </BaseSectionTitle>
    );
  }
);

DefaultTitle.displayName = "DefaultTitle";

export const MainTitle: ForwardRefExoticComponent<
  SectionTitleProps & RefAttributes<HTMLElement>
> = forwardRef(
  (
    { children, className, variant = "h1", color = "text", ...typographyProps },
    ref
  ) => {
    return (
      <SectionTitle
        ref={ref}
        className={className}
        variant={variant}
        color={color}
        {...typographyProps}
      >
        {children}
      </SectionTitle>
    );
  }
);

MainTitle.displayName = "MainTitle";

export const SectionTitle: ForwardRefExoticComponent<
  SectionTitleProps & RefAttributes<HTMLElement>
> = forwardRef(({ children, kind = "default", ...typographyProps }, ref) => {
  switch (kind) {
    case "main":
      return (
        <MainTitle ref={ref} {...typographyProps}>
          {children}
        </MainTitle>
      );
    default:
      return (
        <DefaultTitle ref={ref} {...typographyProps}>
          {children}
        </DefaultTitle>
      );
  }
});

SectionTitle.displayName = "SectionTitle";
