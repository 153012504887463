import { createSlice } from "@reduxjs/toolkit";
import { Languages, copy } from "../constants";

const initialState = {
  [Languages.EN]: { pages: [], copy: copy[Languages.EN] },
  [Languages.ES]: { pages: [], copy: copy[Languages.ES] },
  error: false,
  isLoading: true,
};

export const contentsSlice = createSlice({
  name: "contents",
  initialState,
  reducers: {
    updateContent: (state, { payload: { language, content } }) => {
      state[language] = {
        ...(state[language] || {}),
        ...content,
      };
    },
    updateErrorState: (state, { payload }) => {
      state.error = payload;
    },
    updateLoadingState: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export const { updateContent, updateErrorState, updateLoadingState } =
  contentsSlice.actions;

export default contentsSlice.reducer;
