import "./styles.scss";
import React from "react";
import { Box, BoxProps, ThemeProvider } from "@material-ui/core";
import { theme } from "../../theme";

export type Variant = "primary" | "secondary";

export interface AppButtonProps extends Partial<BoxProps> {
  variant?: Variant;
  disabled?: boolean;
}

export const AppButton: React.FC<AppButtonProps> = ({
  children,
  className = "",
  component,
  variant = "primary",
  margin = "",
  disabled = false,
  ...props
}) => {
  const boxSettings =
    (props.disabled && {
      bgcolor: "disabled.contrastText",
      borderColor: "disabled.main",
      color: "disabled.main",
    }) || (variant === "secondary" && {
      bgcolor: "secondary.contrastText",
      border: 1,
      borderColor: "secondary.main",
    }) || ({ 
      bgcolor: "primary.main", border: 0,
    });
  const boxSpacing = 
      {
        margin: margin
      }
        

  return (
    <ThemeProvider theme={theme}>
      <Box
        className={`AppButton ${className}`}
        component={component}
        color="secondary.main"
        fontWeight="bold"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        data-disabled={disabled}
        {...boxSettings}
        {...boxSpacing}
        {...props}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};

AppButton.displayName = "AppButton";
