import "./styles.scss";
import React, { HTMLProps } from "react";
import { Box, BoxProps } from "@material-ui/core";

export interface TextLinkProps
  extends Partial<BoxProps>,
    Partial<Pick<HTMLProps<HTMLAnchorElement>, "href" | "target" | "rel">> {}

export const TextLink: React.FC<TextLinkProps> = ({
  children,
  className = "",
  component = "a",
  ...props
}) => {
  return (
    <Box component={component} className={`TextLink ${className}`} {...props}>
      {children}
    </Box>
  );
};

TextLink.displayName = "TextLink";
