import "../scss/components/TranslationMenu.scss"
import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import globe from "../assets/EnergiizeAssets/Globe.svg";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { LanguagesContext } from "../contexts";
import { Languages } from "../constants";

const Globe = () => <Box
    component="img"
    sx={{
        height: 50,
        width: 15,
    }}
    alt="Language"
    src={globe}
/>


export default function TranslationsMenu() {
    const { language, setLanguage } = useContext(LanguagesContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = async (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const returnActiveSpanClass = (value, returnValue) => (value === language) ?
        <span className='active'>{returnValue}</span> :
        <span className='inactive'>{returnValue}</span>;

    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Globe /> <ArrowDropDownIcon />
            </Button>
            <Menu className="TranslationMenu"
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={() => handleClose()} key={"globe_selector"} > <Globe /><ArrowDropUpIcon /></MenuItem>
                <MenuItem onClick={() => setLanguage(Languages.EN)} key={"english_selector"} value={Languages.EN}> {returnActiveSpanClass(Languages.EN, "EN")}</MenuItem>
                <MenuItem onClick={() => setLanguage(Languages.ES)} key={"spanish_selector"} value={Languages.ES}>{returnActiveSpanClass(Languages.ES, "ES")}</MenuItem>
            </Menu>
        </div>
    );
}
