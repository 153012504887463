import React from "react";

import { ContentRenderingComponents } from "../../../Page";
import { TitleParagraphCta } from "../../../../TitleParagraphCta";
import { Markdown } from "../../../../Markdown";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";

export const contents: ContentRenderingComponents = {
  "news.apply.paragraph": ContentWithOnDemandTranslation(({ title, body }) => {
    const [button] = body?.match(/```jsx\n.*\n```/g) || [];
    const children = body?.replace(button, "");

    return (
      <TitleParagraphCta
        title={title || ""}
        button={<Markdown>{button}</Markdown>}
        children={children}
        variant="h1"
      />
    );
  }),
};
