import "./styles.scss";
import React from "react";
import { Box, BoxProps, Card, CardProps } from "@material-ui/core";

export type Kind = "default" | "secondary" | "tertiary";

export interface GradientBgProps {
  className?: string;
  kind?: Kind;
}

export const GradientBg: React.FC<GradientBgProps> = ({
  children,
  kind = "default",
  className = "",
}) => {
  return (
    <div data-kind={kind} className={`GradientBg ${className}`}>
      {children}
    </div>
  );
};

GradientBg.displayName = "GradientBg";

export const CardGradientBg: React.FC<CardProps & GradientBgProps> = ({
  children,
  kind = "default",
  className,
  ...props
}) => (
  <Card data-kind={kind} className={`GradientBg ${className}`} {...props}>
    {children}
  </Card>
);

CardGradientBg.displayName = "CardGradientBg";

export const BoxGradientBg: React.FC<BoxProps & GradientBgProps> = ({
  children,
  kind = "default",
  className,
  ...props
}) => (
  <Box data-kind={kind} className={`GradientBg ${className}`} {...props}>
    {children}
  </Box>
);

BoxGradientBg.displayName = "BoxGradientBg";
