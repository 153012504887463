import "../scss/main.scss";
import React from "react";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import EnergiizeLogo from "../assets/EnergiizeAssets/logos/EnergiizeLogo.svg";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ZappyLogo from "../assets/ZappyRideLogo/ZappyRidewhite.png";
import { ContentWrapper } from "./ContentWrapper";
import { useCopy } from "../hooks";

const FooterBoundary = ({ children, className = "" }) => (
  <Grid className={className} container>
    {children}
  </Grid>
);

const Footer = () => {
  const currentLanguage = useCopy();

  return (
    <Grid component="footer" className="Footer" container>
      <ContentWrapper boundaryComponent={FooterBoundary}>
        <Grid className="Footer__logo--container" item xs={12}>
          <Box
            component={"img"}
            src={EnergiizeLogo}
            className="Footer__logo__image"
            alt="Energiize Logo"
          />
        </Grid>

        <Grid
          className="Footer__block"
          xs={12}
          sm={6}
          direction="column"
          container
          item
        >
          <h1 className="Footer__title">{currentLanguage.footer_contactUs}</h1>
          <ul className="Footer__content">
            <li key="phone" className="Footer__row">
              <Box component={PhoneIcon} className="icon" />
              <Box>
                <Typography>
                  {" "}
                  1 (877) ENR-GIZE <br />1 (877) 367-4493
                </Typography>
              </Box>
            </li>
            <li className="Footer__row">
              <Box component={MailIcon} className="icon" />
              <Typography> infrastructure@calstart.org</Typography>
            </li>
            <li className="Footer__row">
              <Box component={LocationOnIcon} className="icon" />
              <Typography>
                {" "}
                48 South Chester Avenue, Pasadena, <br /> California 91106{" "}
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid className="Footer__block" item xs={12}>
            <h1 className="Footer__title Footer__row">
              {currentLanguage.footer_getInContact} <br />
              {currentLanguage.footer_aboutTheLatestNews}
            </h1>
            <Button id="cta_button">
              <a
                href="https://mailchi.mp/3a64c44237ff/energiize-mailing-list"
                target="_blank"
                rel="noreferrer"
              >
                {currentLanguage.footer_subscribe}
              </a>
            </Button>
          </Grid>
          <Grid
            component="div"
            className="Footer__short-survey-block"
            item
            xs={12}
          >
            <h2 className="Footer__title--medium">
              {currentLanguage.footer_notFindingWhatYouNeed}
            </h2>
            <span>
              <a
                href=" https://us20.list-manage.com/survey?u=1517d61702255ccae3d69e1b2&id=71f7ce579a&attribution=false"
                className="Footer__link"
                target="_blank"
                rel="noreferrer"
              >
                {currentLanguage.footer_notFindingWhatYouNeedLink}
              </a>
              &nbsp; {currentLanguage.footer_notFindingWhatYouNeedLinkSurvey}
            </span>
          </Grid>
        </Grid>

        <Grid className="Footer__signature-block" container item xs={12}>
          <Typography id="left">{currentLanguage.footer_poweredBy}</Typography>
          <div className="icon" id="zappy">
            <img src={ZappyLogo} alt="ZappyRide Logo" />
          </div>
        </Grid>
      </ContentWrapper>
    </Grid>
  );
};

export default Footer;
