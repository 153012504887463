import React from "react";
import { Typography, Box, Button } from "@material-ui/core";
import { NavLink, useResolvedPath, useMatch } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import logo from "../../assets/EnergiizeAssets/logos/EnergIIZEColorLogo.svg";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TranslationsMenu from "../TranslationsMenu";
import TranslationsSelect from "../TranslationsSelect";
import { useCopy } from "../../hooks";

import getNavigationMenuDefinition, { SubNavigationMenuProp } from "./navigationMenuDefinition";
import NavigationLink from "./NavigationLink";
import NavigationSubMenu from "./NavigationSubMenu";

interface NavigationItemProps {
    children: React.ReactNode;
    title: React.ReactNode;
    to: string;
    handle: string;
    params: string;
    subNavigationItemsProps: SubNavigationMenuProp;
    icon: React.ReactNode;
}

function NavigationItem ({
    title,
    to,
    icon,
    params,
    subNavigationItemsProps,
    handle,
}: NavigationItemProps) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    const classes = `headerButton${match ? ' selectedTab' : ''}`;

    if (to?.match(/^(https|http|www)/)) {
        return (
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="headerButton"
                href={to}
            >
                {icon} {title}
            </a>
        );
    }

    if (subNavigationItemsProps) {
        return (
            <NavigationSubMenu
                title={title}
                to={to}
                subNavigationItemsProps={subNavigationItemsProps}
                params={params}
                handle={handle}
                className={classes}
            />
        );
    }

    return (
        <NavigationLink className={classes} to={to}>
            {icon} {title}
        </NavigationLink>
    );
}

function NavigationItems() {
    const copy = useCopy();
    const itemsProps = getNavigationMenuDefinition(copy);
    return (
        <>
            {itemsProps.map((itemProps, index) => (
                <NavigationItem key={`navItem-${index}`} {...(itemProps as NavigationItemProps)} />
            ))}
        </>
    );
}

const NavigationMenu = () => {
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

    const [state, setState] = React.useState({
        open: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const Logo = () => (
        <Box
            component="img"
            sx={{
                height: 120,
                paddingTop: "6px",
                paddingLeft: "4px",
            }}
            alt="Energiize"
            src={logo}
        />
    );
    const desktopNav = (
        <AppBar id="Header" position="static">
            <Container className="headerContainer" maxWidth="xl">
                <Toolbar disableGutters>
                    <NavLink to="/">
                        <Logo />
                    </NavLink>
                    <NavigationItems />
                    <TranslationsMenu />
                </Toolbar>
            </Container>
        </AppBar>
    );

    const mobileNav = (
        <Box className="mobile-navigation-bar">
            <Typography className="mobile-navigation-bar-title">
                <Logo />
            </Typography>
            <Button
                className="mobile-navigation-bar-button"
                onClick={toggleDrawer("open", true)}
            >
                {!state["open"] && <MenuIcon style={{ color: "#000" }} />}
                {state["open"] && <CloseIcon style={{ color: "#000" }} />}
            </Button>
            <SwipeableDrawer
                className="mobileNavigation"
                anchor={"left"}
                open={state["open"]}
                onClose={toggleDrawer("open", false)}
                onOpen={toggleDrawer("open", true)}
            >
                <TranslationsSelect />
                <nav className="navigation-before-content">
                    <NavigationItems />
                </nav>
            </SwipeableDrawer>
        </Box>
    );

    return isMobileDevice ? mobileNav : desktopNav;
};

export default NavigationMenu;
