import "./styles.scss";
import React from "react";
import { Page } from "../Page";
import { contents, sections } from "./components";

export const ABOUT = "about";
export const path = ABOUT;
export const title = "About";

export const About: React.FC<AboutProps> = () => {
  return <Page handle={ABOUT} components={{ contents, sections }} pt={0} pb={6} />;
};

About.displayName = "About";
