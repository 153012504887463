import React from "react";
import { Page } from "../Page";
import "./styles.scss";

import { contents, sections } from "./components";

export interface VendorNetworkProps {}

export const VENDOR = "partners";
export const path = VENDOR;
export const title = "Partners";

export const VendorNetwork: React.FC<VendorNetworkProps> = () => {
  return <Page handle={"vendor"} components={{ contents, sections }} pt={0} pb={6} />;
};

VendorNetwork.displayName = "VendorNetwork";
