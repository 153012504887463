import React from "react";
import { ParagraphText, ParagraphTextProps } from "../ParagraphText";
import { AnimatedSectionTitle } from "../AnimatedSectionTitle";

export interface ParagraphTextWithAnimatedTitleProps
  extends ParagraphTextProps {}

export const ParagraphTextWithAnimatedTitle: React.FC<ParagraphTextWithAnimatedTitleProps> =
  ({ title, children, ...props }) => {
    const components = {
      ...(props?.components || {}),
      title: AnimatedSectionTitle,
    };

    return (
      <ParagraphText {...props} title={title} components={components}>
        {children}
      </ParagraphText>
    );
  };

ParagraphTextWithAnimatedTitle.displayName = "ParagraphTextWithAnimatedTitle";
