import "./styles.scss";
import React from "react";
import { ThemeProvider, Box, Typography } from "@material-ui/core";
import { theme } from "../../../theme";
import { useOnDemandMarkupTranslation } from "../../../hooks";
import { Markdown } from "../../Markdown";

export interface NewsCarouselItemProps {
  title: string;
  text: string;
  imageURL: string;
  href:string;
}

export const NewsCarouselItem: React.FC<NewsCarouselItemProps> = ({
  title: initialTitle,
  text: initialText,
  imageURL,
  href
}) => {
  const title = useOnDemandMarkupTranslation(initialTitle);
  const text = useOnDemandMarkupTranslation(initialText);

  return (
    <ThemeProvider theme={theme}>
      <Box className="NewsCarouselItem">
        <div className="NewsCarouselItem__img-container">
        {href ? (
          <a href={href}>
            <img className="NewsCarouselItem__img" src={imageURL} alt={title} />
          </a>
        ) : (
          <img className="NewsCarouselItem__img" src={imageURL} alt={title} />
        )}
        </div>
        <Box p={2} className="NewsCarouselItem__content">
          <Typography className="NewsCarouselItem__title">{title}</Typography>
          <Typography className="NewsCarouselItem__text">
            <Markdown>{text}</Markdown>
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

NewsCarouselItem.displayName = "NewsCarouselItem";
