import "./styles.scss";
import { Grid, Box } from "@material-ui/core";
import {
  Markdown,
  MarkdownWithMainTitle,
  ReversedMarkdownFramedImageFlag,
} from "../../../../Markdown";
import { AnimatedSectionTitle } from "../../../../AnimatedSectionTitle";
import { ContentRenderingComponents, ContentContainer } from "../../../Page";
import { WithResolvedPictureURL } from "../../../Page/WithResolvedPictureURL";
import { Content } from "../../../../../models";
import { CenterableImage } from "../../../../CenterableImage";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";

const CommercialFleet: React.FC<Content> = (props) => {
  return (
    <ReversedMarkdownFramedImageFlag
      {...props}
      variant="h1"
      pictureBackgroundColor="#E5EDF5"
    />
  );
};

const ChooseYourFundingLane: React.FC<Content> = ({
  id,
  handle,
  title,
  body,
  order,
}) => (
  <ContentContainer id={id} handle={handle} py={4}>
    <Box mb={4}>
      <AnimatedSectionTitle>
        Step {order + 1}: {title}
      </AnimatedSectionTitle>
    </Box>
    <Markdown>{body}</Markdown>
  </ContentContainer>
);

const FundingProcessItem = WithResolvedPictureURL(
  ({ id, handle, title, picture, body, order }: Content) => {
    return (
      <ContentContainer
        id={id}
        handle={handle}
        py={4}
        className="FundingProcessItem"
      >
        <Box mb={4} className="FundingProcessItem--flatLink">
          <AnimatedSectionTitle>
            Step {order + 1}: <Markdown>{title}</Markdown>
          </AnimatedSectionTitle>
        </Box>
        <Grid alignItems="center" justifyContent="center" container>
          <Grid
            alignItems="center"
            justifyContent="center"
            xs={12}
            md={5}
            lg={4}
            item
          >
            <CenterableImage src={picture} alt={title} />
          </Grid>
          <Grid xs={12} md={7} lg={8} item>
            <Markdown>{body}</Markdown>
          </Grid>
        </Grid>
      </ContentContainer>
    );
  }
);

export const contents: ContentRenderingComponents = {
  "participate.how-to-participate.intro": ContentWithOnDemandTranslation(
    MarkdownWithMainTitle
  ),
  "participate.intro.commercial-fleet":
    ContentWithOnDemandTranslation(CommercialFleet),
  "participate.funding-process.choose-your-funding-lane":
    ContentWithOnDemandTranslation(ChooseYourFundingLane),
  "participate.funding-process.choose-your-technology":
    ContentWithOnDemandTranslation(FundingProcessItem),
  "participate.funding-process.download-application-packet":
    ContentWithOnDemandTranslation(FundingProcessItem),
  "participate.funding-process.questions":
    ContentWithOnDemandTranslation(FundingProcessItem),
};
