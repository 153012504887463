import "./styles.scss";
import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { BoxGradientBg } from "../GradientBg";

export interface BoxWithOffBgProps extends Partial<BoxProps> {
  components?: {
    contentWrapper: typeof Box;
  };
  withOffBg: boolean;
  animate: boolean;
}

export const BoxWithOffBg: React.FC<BoxWithOffBgProps> = ({
  children,
  className = "",
  withOffBg = true,
  animate = true,
  components: { contentWrapper: ContentWrapper } = { contentWrapper: Box },
  ...props
}) => {
  return (
    <Box
      className={
        `BoxWithOffBg 
        ${animate ? 'BoxWithOffBg--hover' : ''} 
        ${withOffBg ? 'BoxWithOffBg__before' : ''}
        ${className}`
      }
      {...props}
    >
      <BoxGradientBg className="BoxWithOffBg__GradientBg">
        <ContentWrapper className="BoxWithOffBg__content">
          {children}
        </ContentWrapper>
      </BoxGradientBg>
    </Box>
  );
};

BoxWithOffBg.displayName = "BoxWithOffBg";
