import { useContentFromMarkdownCodeBlock } from "../useContentFromMarkdownCodeBlock";

const parseLink = (rawLink: string = "") => {
  return (rawLink.split("\n").filter((line) => line.indexOf("```") === -1) || [
    "/",
  ])[0];
};

export function useMarkdownLinkBlock(body: string) {
  const { block, rest } = useContentFromMarkdownCodeBlock("link", body);
  const link = parseLink(block);

  return { link, body: rest };
}
