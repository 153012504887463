import React from "react";
import { Box } from "@material-ui/core";
import { TextCta } from "../../../../TextCta";
import {
  Markdown,
  MarkdownFramedImageFlag,
  ReversedMarkdownFramedImageFlag,
  MarkdownTitleImageContent,
  MarkdownParagraph,
  MarkdownWithMainTitle,
} from "../../../../Markdown";
import { ContentRenderingComponents } from "../../../Page";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";

export const contents: ContentRenderingComponents = {
  "infrastructure.eligible-equipments.interested":
    ContentWithOnDemandTranslation(({ title, body }) => (
      <Box>
        <TextCta
          text={title || ""}
          cta={<Markdown disallowedElements={["p"]}>{body}</Markdown>}
        />
      </Box>
    )),
  "infrastructure.eligible-equipments.paragraph":
    ContentWithOnDemandTranslation(MarkdownParagraph),
  "infrastructure.eligible-equipments.electric": ContentWithOnDemandTranslation(
    MarkdownTitleImageContent
  ),
  "infrastructure.eligible-equipments.hydrogen": ContentWithOnDemandTranslation(
    MarkdownTitleImageContent
  ),
  "infrastructure.intro.main": ContentWithOnDemandTranslation(
    MarkdownWithMainTitle
  ),
  "infrastructure.more-details.technology": ContentWithOnDemandTranslation(
    MarkdownFramedImageFlag
  ),
  "infrastructure.more-details.make-ready-program":
    ContentWithOnDemandTranslation(ReversedMarkdownFramedImageFlag),
};
