import React from "react";
import { Grid, Box } from "@material-ui/core";
import { ContentWrapper } from "../../../../ContentWrapper";
import { SmoothBg } from "../../../../SmoothBg";
import {
  SectionRenderingComponents,
  ContentComponent,
  ContentContainer,
  sort,
} from "../../../Page";
import { contents as contentsComponents } from "../contents";

export const sections: SectionRenderingComponents = {
  "vendor.want-to": ({ contents }) => (
      <ContentWrapper>
        <Box py={5}>
          <Grid spacing={5} container>
            {sort(contents).map(({ id, handle, title, body, picture }) => {
              return (
                <Grid
                  key={id}
                  item
                  xs={12}
                  md={
                    ["vendor.want-to.title", "vendor.want-to.application-packet"].includes(handle)
                      ? 12
                      : 6
                  }
                  data-model="content"
                  data-id={id}
                  data-handle={handle}
                >
                  <ContentContainer key={id} id={id} handle={handle}>
                    <ContentComponent
                      id={id}
                      handle={handle}
                      title={title}
                      body={body}
                      picture={picture}
                      components={{ contents: contentsComponents }}
                    />
                  </ContentContainer>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </ContentWrapper>
  ),
  "vendor.vendor-types": ({ contents }) => (
    <SmoothBg variant="green" hideBottom mt={10}>
      <ContentWrapper>
        <Box>
            {sort(contents).map(({ id, handle, title, body, picture }) => {
              return (
                  <ContentContainer key={id} id={id} handle={handle}>
                    <ContentComponent
                      id={id}
                      handle={handle}
                      title={title}
                      body={body}
                      picture={picture}
                      components={{ contents: contentsComponents }}
                    />
                  </ContentContainer>
              );
            })}
        </Box>
      </ContentWrapper>
    </SmoothBg>
  ),
  "vendor.find": ({ contents }) => (
    <>
      {sort(contents).map(({ id, handle, title, body, picture }) => {
        return (
          <ContentContainer key={id} id={id} handle={handle}>
            <ContentComponent
              id={id}
              handle={handle}
              title={title}
              body={body}
              picture={picture}
              components={{ contents: contentsComponents }}
            />
          </ContentContainer>
        );
      })}
    </>
  ),
};
