import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import awsconfig from "./aws-config";
import "./index.css";
import { Root } from "./Root";
import reportWebVitals from "./reportWebVitals";
import {
  amplifyApi,
  middleware as amplifyApiMiddleware,
} from "./services/amplify";
import { contentsSlice } from "./slices/contentsSlice";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

Amplify.configure(awsconfig);

export const store = configureStore({
  reducer: {
    [amplifyApi.reducerPath]: amplifyApi.reducer,
    [contentsSlice.name]: contentsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(amplifyApiMiddleware),
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Root />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
