import React from "react";
import { Section } from "../../../models";
import { Page } from "../Page";
import { contents, sections } from "./components";

export interface HomeProps {}

export const HOME = "home";
export const path = "";
export const title = "Home";

const excludeSections = (section: Section) =>
  !["home.participate-categories"].includes(section.handle);

const filters = {
  sections: excludeSections,
};

export const Home: React.FC<HomeProps> = () => {
  return (
    <Page handle={HOME} components={{ contents, sections }} filters={filters} pb={2} />
  );
};

Home.displayName = "Home";
