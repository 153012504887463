import React, { useEffect, useRef, useState } from "react";
import { Menu, MenuItem, useMediaQuery, useTheme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { withStyles } from "@material-ui/styles";

import NavigationFlatButton from "./NavigationFlatButton";
import NavigationLink from "./NavigationLink";
import { SubNavigationMenuProp } from "./navigationMenuDefinition";

export interface NavigationSubMenuProps {
    className?: string;
    title: React.ReactNode;
    to: string;
    handle: string;
    params: string;
    subNavigationItemsProps: SubNavigationMenuProp;
};

const StyledMenu = withStyles({
  paper: {
    marginTop: '56px',
    marginLeft: '-16px',
  },
})((props) => (
  <Menu {...props} />
));

export default function NavigationSubMenu ({
    title,
    to,
    handle,
    params,
    subNavigationItemsProps,
    className,
}: NavigationSubMenuProps) {
    const ref = useRef(null);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOpen = () => {
        const pathWithSearch = params ? `${to}?${params}` : to;
        navigate(pathWithSearch);
        setOpen(true);
    };

    const handleClose = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const section = searchParams.get('section');

        document.querySelector?.(`[data-handle="${section}"]`)?.scrollIntoView({ behavior: 'smooth' });
        setOpen(false);
    };

    useEffect(() => {
        if (window.location.pathname === to && window.location.search?.includes('sub_nav')) {
            setOpen(true);
            navigate(to);
        }
    }, [to, params, navigate]);


    
    return (
        <div className="NavigationSubMenu">
            <NavigationFlatButton className={className} onClick={handleOpen} fwdRef={ref}>
                {title}
                {!isMobileDevice && (open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
            </NavigationFlatButton>
            <StyledMenu
                id={`${handle}-sub-navigation`}
                anchorEl={ref.current}
                open={open}
                onClose={handleClose}
                transitionDuration={25}
            >
                {subNavigationItemsProps.map(({ handle: subHandle, title: subTitle }) => (
                    <MenuItem disableRipple key={subHandle} onClick={handleClose}>
                        <NavigationLink className={className} to={`${to}?section=${subHandle}`}>
                            {subTitle}
                        </NavigationLink>
                    </MenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}