import React from "react";
import { useContents, useGaTracker } from "./hooks";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import {
  VendorNetwork,
  vendorNetworkPath,
  Infrastructure,
  infrastructurePath,
  About,
  aboutPath,
  Participate,
  participatePath,
  News,
  newsPath,
  Home,
  IRCPage,
  ircPath,
} from "./components/pages";
import { LanguagesProvider } from "./contexts";
import { theme } from "./theme";

export const Root = () => {
  useContents();
  useGaTracker();

  return (
    <LanguagesProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={`/${aboutPath}`} element={<About />} />
          <Route path={`/${infrastructurePath}`} element={<Infrastructure />} />
          <Route path={`/${vendorNetworkPath}`} element={<VendorNetwork />} />
          <Route path={`/${participatePath}`} element={<Participate />} />
          <Route path={`/${ircPath}`} element={<IRCPage />} />
          <Route path={`/${newsPath}`} element={<News />} />
          <Route path="/irc" element={<Navigate to={`/${ircPath}`} replace />}/>
          <Route path="/vendors" element={<Navigate to={`/${vendorNetworkPath}`} replace />}/>
        </Routes>
      </ThemeProvider>
    </LanguagesProvider>
  );
};
