export const TIMEOUT = 300;

export const resolveCardsHeights = (ref, setHeight) => {
  /**
   * Had to wait for alice carousel do whatever it does with
   * the DOM elements before getting the height of the highest
   * one to set as the height of all the cards
   */
  const timeout = setTimeout(() => {
    const cards = [].slice.call(
      ref.current.getElementsByClassName("WebinarCard--container")
    );
    const h = cards.reduce((height, card) => {
      const h = Math.ceil(card.getBoundingClientRect().height);

      if (h < height) {
        return height;
      }

      return h;
    }, 0);

    setHeight(h);
    clearTimeout(timeout);
  }, TIMEOUT);
};

