import { useEffect } from "react";

const DEFAULT_DELAY_TIMING = 300;

export function useDOMContentLoaded(
  callback: () => void,
  { delay, timing }: { delay: boolean; timing?: number } = {
    delay: false,
    timing: DEFAULT_DELAY_TIMING,
  }
) {
  useEffect(() => {
    const handleLoaded = () => {
      if (!delay) {
        return callback();
      }

      const timeout = setTimeout(() => {
        callback();
        clearTimeout(timeout);
      }, timing);
    };

    window.addEventListener("DOMContentLoaded", handleLoaded);

    return () => window.removeEventListener("DOMContentLoaded", handleLoaded);
  }, [callback, delay, timing]);
}
