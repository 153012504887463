import React, { useRef, useEffect, PropsWithChildren } from "react";
import sortBy from "lodash.sortby";
import { Box } from "@material-ui/core";
import HeaderFooter from "../HeaderFooter";
import { ContentWrapper } from "../../ContentWrapper";
import { Paragraph } from "../../Paragraph";
import { AnimatedSectionTitle } from "../../AnimatedSectionTitle";
import { Markdown } from "../../Markdown";
import { usePageData } from "../../../hooks";
import { Content, Section } from "../../../models";
import { ContentWithOnDemandTranslation } from "./ContentWithOnDemandTranslation";

export interface RenderingComponents<T> {
  [key: string]: React.FC<T>;
}

export interface ContentRenderingComponents
  extends RenderingComponents<Content> {}

export interface SectionRenderingComponents
  extends RenderingComponents<Section> {}

export interface PageProps {
  before?: React.ReactElement;
  after?: React.ReactElement;
  handle: string;
  components?: {
    sections?: SectionRenderingComponents;
    contents?: ContentRenderingComponents;
  };
  [key: string]: any;
  filters?: {
    sections?: (section: Section) => boolean;
  };
}

export function sort(entries: Array<any> | null = []) {
  return sortBy(entries, ["order"]);
}

export interface DefaultContentProps extends Partial<Content> {}

export const DefaultContent: React.FC<DefaultContentProps> =
  ContentWithOnDemandTranslation(({ title, body }) => {
    return (
      <>
        <AnimatedSectionTitle kind="main">{title}</AnimatedSectionTitle>
        <Paragraph>
          <Markdown>{body}</Markdown>
        </Paragraph>
      </>
    );
  });

export interface ContentComponentProps
  extends Pick<PageProps, "components">,
    Pick<Content, "id" | "handle" | "title" | "body" | "picture" | "order"> {}

export const ContentComponent: React.FC<ContentComponentProps> = ({
  components = {},
  ...props
}) => {
  if (!components?.contents || !components?.contents[props.handle]) {
    return <DefaultContent {...props} />;
  }

  const Component = components.contents[props.handle];

  return <Component {...props} />;
};

export interface ContentContainerProps extends Pick<Content, "id" | "handle"> {}

export const ContentContainer: React.FC<
  PropsWithChildren<ContentContainerProps>
> = ({ id, handle, children, ...boxProps }) => (
  <Box data-model="content" data-id={id} data-handle={handle} {...boxProps}>
    {children}
  </Box>
);

export interface DefaultSectionProps
  extends Partial<Section>,
    Pick<PageProps, "components"> {}

export const DefaultSection: React.FC<DefaultSectionProps> = ({
  components = {},
  contents = [],
}) => {
  return (
    <ContentWrapper>
      {sort(contents).map(({ id, handle, title, body, picture, order }) => {
        return (
          <ContentContainer key={id} id={id} handle={handle}>
            <ContentComponent
              id={id}
              handle={handle}
              title={title}
              body={body}
              picture={picture}
              components={components}
              order={order}
            />
          </ContentContainer>
        );
      })}
    </ContentWrapper>
  );
};

export interface SectionComponentProps
  extends Pick<PageProps, "components">,
    Pick<Section, "id" | "handle" | "contents" | "title"> {}

export const SectionComponent: React.FC<SectionComponentProps> = ({
  components,
  ...props
}) => {
  if (!(components?.sections || {})[props.handle]) {
    return <DefaultSection components={components} {...props} />;
  }

  const Component = (
    components?.sections as { [key: string]: React.FC<SectionComponentProps> }
  )[props.handle];

  return <Component components={components} {...props} />;
};

export interface SectionContainerProps extends Pick<Section, "id" | "handle"> {}

export const SectionContainer: React.FC<SectionContainerProps> = ({
  id,
  handle,
  children,
  ...boxProps
}) => (
  <Box
    component="section"
    data-model="section"
    data-id={id}
    data-handle={handle}
    {...boxProps}
  >
    {children}
  </Box>
);

export const contentsAsObject = (contents: Array<Content>) =>
  contents.reduce((acc, content) => {
    acc[content.handle] = content;

    return acc;
  }, {} as { [key: string]: Content });

const DEFAULT_FILTERS = { sections: () => true };

export const Page: React.FC<PageProps> = ({
  before,
  after,
  handle,
  components = {},
  /**
   * does not filter out anything by default
   */
  filters: { sections } = DEFAULT_FILTERS,
  props = {},
  ...pageBoxProps
}) => {
  const { page, error, isLoading } = usePageData(handle);
  const sectionsList = (page?.sections || []).filter(sections);
  const ref = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const section = searchParams.get("section");

    if (!isLoading && ref.current && section) {
      ref.current
        .querySelector?.(`[data-handle="${section}"]`)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isLoading]);

  return (
    <HeaderFooter>
      {before}
      <div ref={ref}>
        <Box py={12} {...pageBoxProps}>
          {!isLoading &&
            !error &&
            sort(sectionsList).map(({ id, handle, title, contents }) => {
              const sectionBoxProps = props?.sections?.[handle];
              return (
                <SectionContainer
                  key={id}
                  id={id}
                  handle={handle}
                  {...sectionBoxProps}
                >
                  <SectionComponent
                    id={id}
                    handle={handle}
                    contents={contents}
                    components={components}
                    title={title}
                  />
                </SectionContainer>
              );
            })}
        </Box>
      </div>
      {after}
    </HeaderFooter>
  );
};

Page.displayName = "Page";
