import React from "react";
import {
  MarkdownWithMainTitle,
  MarkdownBanner,
  MarkdownFramedImageFlag,
  ReversedMarkdownFramedImageFlag,
  ReversedMarkdownFramedImageFlagWithMainTitle,
} from "../../../../Markdown";
import { WithWrappingBox } from "../../../../pages/Page/WithWrappingBox";
import { Content } from "../../../../../models";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";

export const WithVerticalSpacing = (Component: React.FC<Content>) =>
  function RenderComponentWithVerticalSpacing(props: Content) {
    const WrappedComponent = WithWrappingBox({ py: { xs: 4, md: 8 } })(
      Component
    );

    return <WrappedComponent {...props} />;
  };

export const contents: ContentRenderingComponents = {
  "about.intro.our-mission": ContentWithOnDemandTranslation(
    ReversedMarkdownFramedImageFlagWithMainTitle
  ),
  "about.our-story.banner": ContentWithOnDemandTranslation(MarkdownBanner),
  "about.advisory-committees.intro": ContentWithOnDemandTranslation(
    WithVerticalSpacing(MarkdownWithMainTitle)
  ),
  "about.advisory-committees.funding-advisory-committee":
    ContentWithOnDemandTranslation(MarkdownFramedImageFlag),
  "about.advisory-committees.technical-advisory-committee":
    ContentWithOnDemandTranslation(ReversedMarkdownFramedImageFlag),
  "about.advisory-committees.energiize-equity-workgroup":
    ContentWithOnDemandTranslation(MarkdownFramedImageFlag),
};
