import { Link } from "react-router-dom";

export interface NavigationLinkProps {
    className?: string;
    children: React.ReactNode;
    to: string;
};

export default function NavigationLink({ 
    className = '',
    children,
    to,
    ...props
}: NavigationLinkProps) {
    return (
        <Link
            className={`NavigationLink ${className}`}
            to={to}
            {...props}
        >
            {children}
        </Link>
    );
}