import React from "react";
import { Markdown } from "../Markdown";
import { TitleImageContent } from "../../TitleImageBullets";
import { Content } from "../../../models";
import { WithResolvedPictureURL } from "../../../components/pages/Page/WithResolvedPictureURL";
import { Paragraph } from "../../Paragraph";

export interface MarkdownTitleImageContentProps
  extends Partial<Pick<Content, "title" | "picture" | "body">> {}

export const MarkdownTitleImageContent: React.FC<MarkdownTitleImageContentProps> =
  WithResolvedPictureURL(({ title, picture, body }) => (
    <TitleImageContent title={title || ""} imageURL={picture || ""}>
      <Paragraph>
        <Markdown>{body}</Markdown>
      </Paragraph>
    </TitleImageContent>
  ));

MarkdownTitleImageContent.displayName = "MarkdownTitleImageContent";
