import { useContext } from "react";
import { useSelector } from "react-redux";
import { LanguagesContext } from "../../contexts";

export function useCopy() {
  const { language } = useContext(LanguagesContext);
  const contents = useSelector((state) => state.contents);

  return contents[language]?.copy || {};
}
