import {
  CSSProperties,
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
} from "react";
import { Variant, variants } from "../index";

export interface SVGProps {
  d: string;
  className?: string;
  style: CSSProperties;
  variant: Variant;
}

const SVGWrapper: ForwardRefExoticComponent<
  SVGProps & RefAttributes<HTMLDivElement>
> = forwardRef(({ className = "", variant = "blue", d, style }, ref) => (
  <div ref={ref} className={className} style={style} aria-hidden="true">
    <svg
      width="100%"
      viewBox="0 0 1440 393"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={d} fill={variants[variant]} />
    </svg>
  </div>
));

type BGPiece = ForwardRefExoticComponent<
  Pick<SVGProps, "className" | "style" | "variant"> &
    RefAttributes<HTMLDivElement>
>;

export const Top: BGPiece = forwardRef(
  ({ className = "", variant, style }, ref) => (
    <SVGWrapper
      ref={ref}
      variant={variant}
      className={className}
      style={style}
      d="M0 185.375C0 185.375 0 -9.73553 0 0.380176C0 177.106 143.265 185.375 319.991 185.375H1221.97C1338.54 185.375 1433.27 276.473 1440 391.369L0 392.791V185.375Z"
    />
  )
);

export const Bottom: BGPiece = forwardRef(
  ({ className = "", variant, style }, ref) => (
    <SVGWrapper
      ref={ref}
      variant={variant}
      className={className}
      style={style}
      d="M1440 207.416C1440 207.416 1440 402.526 1440 392.411C1440 215.685 1296.74 207.416 1120.01 207.416L218.034 207.416C101.457 207.416 6.72981 116.317 1.44576e-05 1.42182L1440 -1.15237e-05L1440 207.416Z"
    />
  )
);
