import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  FC,
  ElementType,
} from "react";
import { BoxProps, TypographyProps, ThemeProvider } from "@material-ui/core";
import { theme } from "../../theme";
import { SectionTitle, SectionTitleProps } from "../SectionTitle";
import { Paragraph } from "../Paragraph";

export interface ParagraphTextProps
  extends Partial<Pick<TypographyProps, "variant">>,
    Partial<Pick<SectionTitleProps, "kind">>,
    Partial<Pick<BoxProps, "mb" | "my" | "alignItems" | "justifyContent" | "color">> {
  title: string;
  titleClassName?: string;
  components?:
    | {
        title?: FC<SectionTitleProps>;
        paragraph?: FC | string | undefined;
      }
    | undefined;
}

export const ParagraphText: ForwardRefExoticComponent<
  React.PropsWithChildren<ParagraphTextProps> & RefAttributes<HTMLDivElement>
> = forwardRef(
  (
    {
      title,
      children,
      variant = "h2",
      kind = "default",
      components,
      ...boxProps
    },
    ref
  ) => {
    const Title = (components?.title || SectionTitle) as FC<SectionTitleProps>;
    const titleProps = { variant, kind };
    const ParagraphComponent = (
      components?.paragraph && typeof components?.paragraph !== "string"
        ? components?.paragraph
        : Paragraph
    ) as FC<Pick<BoxProps, "component">>;

    return (
      <ThemeProvider theme={theme}>
        <div ref={ref}>
          <Title {...titleProps}>{title}</Title>
          <ParagraphComponent
            component={
              typeof components?.paragraph === "string"
                ? (components.paragraph as ElementType<any>)
                : undefined
            }
          >
            {children}
          </ParagraphComponent>
        </div>
      </ThemeProvider>
    );
  }
);

ParagraphText.displayName = "ParagraphText";
