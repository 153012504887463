import { useEffect } from "react";
import GaTracker from "../../utils/GaTracker";

export function useGaTracker() {
  useEffect(() => {
    const path = window.location?.pathname;
    const page = path + window.location.search;

    GaTracker.initialize();
    GaTracker.trackPage(page);
  }, []);
}
