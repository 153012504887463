import "./styles.scss";
import { Box } from "@material-ui/core";
import { BannerSection } from "../../BannerSection";
import { AnimatedSectionTitle } from "../../AnimatedSectionTitle";
import { Markdown } from "../Markdown";
import { WithResolvedPictureURL } from "../../../components/pages/Page/WithResolvedPictureURL";

export const MarkdownBanner: React.FC<Partial<Content>> =
  WithResolvedPictureURL(({ body, picture, title }) => (
    <BannerSection img={picture}>
      <Box py={8}>
        <AnimatedSectionTitle kind="main">{title}</AnimatedSectionTitle>
        <Markdown>{body}</Markdown>
      </Box>
    </BannerSection>
  ));

MarkdownBanner.displayName = "MarkdownBanner";
