import "../scss/components/TranslationMenu.scss";
import React, { useContext } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { LanguagesContext } from "../contexts";
import { Languages } from "../constants";

export default function TranslationSelect() {
    const { language, setLanguage } = useContext(LanguagesContext);
    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <div>
            <FormControl variant="filled" className="form_container">
                <InputLabel id="demo-simple-select-outlined-label">
                    Language
                </InputLabel>
                <Select
                    className="TranslationsSelect_menu"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={language}
                    onChange={handleChange}
                    label="Age"
                >
                    <MenuItem key={"english_selector"} value={Languages.EN}>
                        English
                    </MenuItem>
                    <MenuItem key={"spanish_selector"} value={Languages.ES}>
                        Español
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}
