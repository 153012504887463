import "./styles.scss";
import React from "react";
import {
  Box,
  Grid,
  Typography,
  TypographyProps,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Markdown, MarkdownFramedImageFlag } from "../../../../Markdown";
import { ContentRenderingComponents } from "../../../Page";
import { ContentWithOnDemandTranslation } from "../../../Page/ContentWithOnDemandTranslation";
import { WithResolvedPictureURL } from "../../../Page/WithResolvedPictureURL";
import { invertedTheme } from "../../../../../theme";
import { ContentWrapper } from "../../../../ContentWrapper";
import { LogosRow } from "../../../../LogosRow";
import { AnimatedSectionTitle } from "../../../../AnimatedSectionTitle";
import { BannerSection } from "../../../../BannerSection";
import { SubscribeSection } from "../../../../../ComponentMetadata/FundingMetadata";
import { Content } from "../../../../../models";
import { useAnimateOnceOnScroll } from "../../../../../hooks";

const WhiteTypography = ({ children, ...props }: TypographyProps) => (
  <ThemeProvider theme={invertedTheme}>
    <Box mb={4}>
      <Typography {...props} color="secondary">
        {children}
      </Typography>
    </Box>
  </ThemeProvider>
);

const HomeHeroSubmit: React.FC<Content> = ({ body }) => {
  const { animate, ref } = useAnimateOnceOnScroll();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile ? (
    <div className="HomeHeroSubmit--container" ref={ref}>
      <ContentWrapper>
        <Box
          data-animate={animate}
          className="HomeHeroSubmit"
          py={{ md: 3, xs: 1 }}
          px={{ md: 6, xs: 4 }}
        >
          <Markdown>{body}</Markdown>
        </Box>
      </ContentWrapper>
    </div>
  ) : (
    <div className="HomeHeroSubmit--container" ref={ref}>
      <Box
        data-animate={animate}
        className="HomeHeroSubmit"
        py={{ md: 3, xs: 1 }}
        px={{ md: 6, xs: 4 }}
      >
        <Markdown>{body}</Markdown>
      </Box>
    </div>
  );
};

HomeHeroSubmit.displayName = "HomeHeroSubmit";

export const HomeHeroIncentives: React.FC<Content> = ({ title, body }) => (
  <ContentWrapper>
    <Box py={{ xs: 4, md: 0 }}>
      <WhiteTypography variant="h1">{title}</WhiteTypography>
      <Markdown
        components={{
          p: WhiteTypography,
        }}
      >
        {body}
      </Markdown>
    </Box>
  </ContentWrapper>
);

export const contents: ContentRenderingComponents = {
  "home.hero.incentives": ContentWithOnDemandTranslation(HomeHeroIncentives),
  "home.hero.submit": ContentWithOnDemandTranslation(HomeHeroSubmit),
  "home.about.intro": ContentWithOnDemandTranslation(MarkdownFramedImageFlag),
  "home.about.dashboard": ContentWithOnDemandTranslation(MarkdownFramedImageFlag),
  "home.signup.intro": ContentWithOnDemandTranslation(({ title, body }) => {
    return <SubscribeSection title={title} cta={<Markdown>{body}</Markdown>} />;
  }),
  "home.irc.banner": ContentWithOnDemandTranslation(
    WithResolvedPictureURL(({ title, body, picture }) => {
      return (
        <BannerSection img={picture} mt={{ xs: 3, sm: 3, md: 6, lg: 6 }}>
          <Box py={8}>
            <Grid container>
              <Grid lg={8} xs={12} item>
                <WhiteTypography variant="h1">{title}</WhiteTypography>
                <Markdown
                  components={{
                    p: ({ children }) => (
                      <WhiteTypography variant="body1">
                        {children}
                      </WhiteTypography>
                    ),
                  }}
                >
                  {body}
                </Markdown>
              </Grid>
            </Grid>
          </Box>
        </BannerSection>
      );
    })
  ),
  "home.brought-to-you-by.main": ContentWithOnDemandTranslation(
    ({ title, body }) => {
      return (
        <>
          <AnimatedSectionTitle>{title}</AnimatedSectionTitle>
          <LogosRow>
            <Markdown>{body}</Markdown>
          </LogosRow>
        </>
      );
    }
  ),
};
