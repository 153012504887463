import "./styles.scss";
import ImageFrame from "../../assets/backgrounds/image-frame.svg";
import React, { useRef } from "react";
import { FrameProps } from "../Frame";
import { useImageHasLoaded, useBoundingClientRect } from "../../hooks";

export interface PictureWithOffBorderProps extends FrameProps {
  animate?: boolean;
}

export const PictureWithOffBorder: React.FC<PictureWithOffBorderProps> = ({
  src,
  alt,
  animate = true,
}) => {
  const imgRef = useRef();
  const { ref: frameRef, rect: frameRect } = useBoundingClientRect();
  const { hasLoaded: imgLoaded } = useImageHasLoaded({ src, ref: imgRef });
  const height = `${Math.ceil(frameRect.height)}px`;
  const width = `${Math.ceil(frameRect.width)}px`;

  return (
    <div data-animate={imgLoaded && animate} className="PictureWithOffBorder">
      <div
        data-animate={imgLoaded && animate}
        className="PictureWithOffBorder__img-container"
        style={{
          height,
          width,
        }}
      >
        <img
          className="PictureWithOffBorder__img"
          ref={imgRef}
          src={src}
          alt={alt}
        />
      </div>
      <img
        className="PictureWithOffBorder__svg"
        ref={frameRef}
        src={ImageFrame}
        role="presentation"
        alt=""
      />
    </div>
  );
};

PictureWithOffBorder.displayName = "PictureWithOffBorder";
