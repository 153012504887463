import React from "react";
import { useAmplifyContentImage } from "../../../../hooks";
import { Content } from "../../../../models";

export const WithResolvedPictureURL = (Component: React.FC<Content>) =>
  function RenderComponentWithPicture(props: Content) {
    const { url } = useAmplifyContentImage(props.picture);

    return url ? <Component {...props} picture={url} /> : <Component {...props} />;
  };
