import "./styles.scss";
import React from "react";
import { Box, Typography, ThemeProvider } from "@material-ui/core";
import { Markdown } from "../../../../../Markdown";
import { theme } from "../../../../../../theme";

export interface SignupBoxProps {
  title: string;
  body: string;
}

export const SignupBox: React.FC<SignupBoxProps> = ({ title, body }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box py={4} className="SignupBox">
        <Typography className="SignupBox__title">{title}</Typography>
        <Markdown>{body}</Markdown>
      </Box>
    </ThemeProvider>
  );
};

SignupBox.displayName = "SignupBox";
