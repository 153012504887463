import "./styles.scss";
import React from "react";
import { Page } from "../Page";
import { contents, sections, sectionProps } from "./components";

export interface IRCProps {}

export const IRC = "resources";
export const path = IRC;
export const title = "Resources";

export const IRCPage: React.FC<IRCProps> = () => {
  return (
    <Page
      handle={"irc"}
      components={{ contents, sections }}
      pt={0}
      props={{ sections: sectionProps }}
      pb={6}
    />
  );
};

IRCPage.displayName = "IRCPage";
