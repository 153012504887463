import "./styles.scss";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, BoxProps } from "@material-ui/core";
import { BoxWithOffBg } from "../BoxWithOffBg";
import { useAnimateOnceOnScroll } from "../../hooks";

export interface ImageTextProps extends Partial<BoxProps> {
  imageURL: string;
  imageAlt?: string;
  link?: string;
  onClick?: () => void;
  animate?: boolean;
  expandedContent?: React.ReactNode;
  withOffBg?: boolean;
  withGradientBg?: boolean;
}

export enum Mode {
  AsLink = "As link",
  AsButton = "As button",
  AsExpandable = "As Expandable",
  AsPlainText = "As plain text",
}

export const resolveMode = ({
  link,
  onClick,
  expandedContent,
}: Pick<ImageTextProps, "link" | "onClick" | "expandedContent">): Mode => {
  if (expandedContent) {
    return Mode.AsExpandable;
  }

  if (onClick) {
    return Mode.AsButton;
  }

  if (link) {
    return Mode.AsLink;
  }

  return Mode.AsPlainText;
};

export const ImageTextContentContainer: React.FC<Partial<BoxProps>> = ({
  children,
  ...props
}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    className="ImageText__content"
    {...props}
  >
    {children}
  </Box>
);

ImageTextContentContainer.displayName = "ImageTextContentContainer";

export const ChevronIcon: React.FC = () => (
  <svg
    width="13"
    height="22"
    viewBox="0 0 13 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.064 0.999999L11.0714 11.0074L1.064 21.0147"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

ChevronIcon.displayName = "ChevronIcon";

export const ChevronDownIcon: React.FC = () => (
  <svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0152 1.42386L11.0078 11.4312L1.00046 1.42386"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

ChevronDownIcon.displayName = "ChevronDownIcon";

export interface ExpandableImageTextContentProps
  extends Pick<ImageTextProps, "onClick" | "expandedContent"> {
  initialOpenState?: boolean;
}

export const ExpandableImageTextContent: React.FC<ExpandableImageTextContentProps> =
  ({
    onClick = () => null,
    expandedContent,
    initialOpenState = false,
    children,
  }) => {
    const [isOpen, setIsOpen] = useState(initialOpenState);

    const handleClick = () => {
      onClick();
      setIsOpen((isOpen) => !isOpen);
    };

    return (
      <div>
        <ImageTextContentContainer
          role="button"
          onClick={handleClick}
          data-testid="container"
          data-open={isOpen}
        >
          <Box className="ImageText__expandable-content">
            <div className="ImageText__expandable-content--title">
              {children}
              <span
                className="ImageText__expandable-content__icon"
                data-open={isOpen}
              >
                <ChevronDownIcon />
              </span>
            </div>
            <div
              className="ImageText__expandable-content__content"
              data-open={isOpen}
            >
              {expandedContent}
            </div>
          </Box>
        </ImageTextContentContainer>
      </div>
    );
  };

ExpandableImageTextContent.displayName = "ExpandableImageTextContent";

export const Link = ({
  link,
  children,
  ...props
}: {
  link?: string;
  children: any;
}) => {
  if (link?.match(/^(https|http|www)/)) {
    return (
      <a href={link} {...props} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={`..${link}`} {...props}>
      {children}
    </RouterLink>
  );
};

export const ImageTextContent: React.FC<
  Pick<ImageTextProps, "link" | "onClick" | "expandedContent">
> = ({ link, onClick, expandedContent, children }) => {
  const mode = resolveMode({ link, onClick, expandedContent });

  switch (mode) {
    case Mode.AsLink:
      return (
        <ImageTextContentContainer onClick={onClick}>
          <Link className="ImageText__link" link={link}>
            {children}
            <ChevronIcon />
          </Link>
        </ImageTextContentContainer>
      );
    case Mode.AsButton:
      return (
        <ImageTextContentContainer
          role="button"
          onClick={onClick}
          data-testid="container"
        >
          <div className="ImageText__title">{children}</div>
          <ChevronIcon />
        </ImageTextContentContainer>
      );
    case Mode.AsExpandable:
      return (
        <ExpandableImageTextContent
          onClick={onClick}
          expandedContent={expandedContent}
        >
          <div className="ImageText__title">{children}</div>
        </ExpandableImageTextContent>
      );
    default:
      return (
        <ImageTextContentContainer>
          <div className="ImageText__title">{children}</div>
        </ImageTextContentContainer>
      );
  }
};

export const ImageText: React.FC<ImageTextProps> = ({
  children,
  imageURL,
  imageAlt,
  link,
  onClick,
  expandedContent,
  animate: shouldAnimate = true,
  withOffBg = true,
  withGradientBg = true,
  ...boxProps
}) => {
  const { ref, animate } = useAnimateOnceOnScroll();

  if (!imageURL) {
    throw new Error("The `imageURL` prop is required.");
  }

  if (link && onClick) {
    console.warn(
      "If both a `link` and `onClick` props are passed in, the `link` will be ignored."
    );
  }

  return (
    <div ref={ref}>
      <BoxWithOffBg
        withGradientBg={withGradientBg}
        withOffBg={withOffBg}
        animate={shouldAnimate}
        data-animate={shouldAnimate}
        className={`ImageText ${
          shouldAnimate && animate ? "ImageText--animated" : ""
        }`}
        {...boxProps}
      >
        {link && (
          <Link link={link} className="ImageText--link">
            <div />
          </Link>
        )}
        <Box className="ImageText__img-container">
          <img className="ImageText__img" src={imageURL} alt={imageAlt} />
        </Box>
        <ImageTextContent
          onClick={onClick}
          link={link}
          expandedContent={expandedContent}
        >
          {children}
        </ImageTextContent>
      </BoxWithOffBg>
    </div>
  );
};

ImageText.displayName = "ImageText";
