import React, { Children, ReactChild } from "react";
import { Box } from "@material-ui/core";
import { Flag, FlagProps } from "../Flag";

export interface FlagWithCtasProps extends FlagProps {
  ctas: Array<React.ReactNode>;
}

export const FlagWithCtas: React.FC<FlagWithCtasProps> = ({
  children,
  ctas = [],
  proportion = [4, 8],
  reverse = false,
  component = "div",
  ...props
}) => {
  const validChildrenArray = Children.toArray(children).filter(
    React.isValidElement
  );

  return (
    <Flag
      proportion={proportion}
      reverse={reverse}
      component={component}
      alignItems="center"
      keepProportionWhenReversed
      {...props}
    >
      {Children.map(validChildrenArray, (child: ReactChild, index) => (
        <Box>
          {child}
          {index > 0 && ctas.length > 0 && (
            <Box mt={4} display="flex">
              {ctas.map((cta, index) => (
                <Box ml={index > 0 ? 4 : 0} key={`cta-${index}`}>
                  {cta}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Flag>
  );
};

FlagWithCtas.displayName = "FlagWithCtas";
