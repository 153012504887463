import { useEffect } from "react";
import { useContext } from "react";
import { Languages } from "../../constants";
import { LanguagesContext } from "../../contexts";
import { TJO } from "../../utils/TJO";
import { reformatTranslatedMarkup } from "../../utils/reformatTranslatedMarkup";
import { useLocalStorageState } from "../useLocalStorageState";

export function useOnDemandMarkupTranslation(initialContent = "") {
  const { language } = useContext(LanguagesContext);
  const [translatedContent, setTranslatedContent] = useLocalStorageState(
    `${Languages.ES}`.concat(JSON.stringify(initialContent)),
    initialContent
  );

  useEffect(() => {
    if (
      language === Languages.EN ||
      translatedContent !== initialContent ||
      initialContent === ""
    ) {
      return;
    }

    TJO.translate({ content: initialContent }, language)
      .then(({ content }) => {
        setTranslatedContent(reformatTranslatedMarkup(content));
      })
      .catch((error) => console.error("useOnDemandMarkupTranslation", error));
  }, [initialContent, language, setTranslatedContent, translatedContent]);

  return language === Languages.EN ? initialContent : translatedContent;
}
