import "./styles.scss";
import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { FlippingCardProps } from "../../FlippingCard";
import { Tile } from "../../Tile";
import { theme } from "../../../theme";
import { useCustomTranslation } from "../../../hooks";

export interface PartnerTileProps
  extends Pick<FlippingCardProps, "data-testid"> {
  imgURL: string;
  name: string;
  text: React.ReactNode;
  displayTitleInFrontSide?: boolean;
}

export const PartnerTileTitle: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Typography className="PartnerTileTitle" variant="body1">
      {children}
    </Typography>
  </ThemeProvider>
);

PartnerTileTitle.displayName = "PartnerTileTitle";

export const PartnerTileContainer: React.FC = ({ children }) => (
  <Box p={3} display="flex" flexDirection="column" alignItems="center">
    {children}
  </Box>
);

PartnerTileContainer.displayName = "PartnerTileContainer";

export const PartnerTileBack: React.FC<
  Pick<PartnerTileProps, "name" | "text">
> = ({ name, text }) => (
  <ThemeProvider theme={theme}>
    <PartnerTileContainer>
      <PartnerTileTitle>{name}</PartnerTileTitle>
      <Box mt={4}>
        <div className="PartnerTileBack__text">{text}</div>
      </Box>
    </PartnerTileContainer>
  </ThemeProvider>
);

PartnerTileBack.displayName = "PartnerTileBack";

export const PartnerTile: React.FC<PartnerTileProps> = ({
  imgURL,
  name: originalName,
  text,
  displayTitleInFrontSide = false,
  ...props
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const toggleIsFlipped = () => setIsFlipped((isFlipped) => !isFlipped);
  const name = useCustomTranslation(originalName);

  return (
    <Tile
      data-testid="partner-tile"
      back={<PartnerTileBack name={name} text={text} />}
      isFlipped={isFlipped}
      onClick={toggleIsFlipped}
    >
      <PartnerTileContainer>
        <img src={imgURL} alt={name} />
        {displayTitleInFrontSide && (
          <Box mt={6}>
            <PartnerTileTitle>{name}</PartnerTileTitle>
          </Box>
        )}
      </PartnerTileContainer>
    </Tile>
  );
};

PartnerTile.displayName = "PartnerTile";
