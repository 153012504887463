import * as React from "react";
import isEmpty from "lodash.isempty";

export interface MaybeMapProps<T = unknown> {
  value?: T | T[];
  map: (x: T) => React.ReactNode;
}

export const MaybeMap: React.FC<MaybeMapProps> = ({ value, map }) => {
  if (isEmpty(value)) return null;

  return <>{Array.isArray(value) ? value.map(map) : [value].map(map)}</>;
};

MaybeMap.displayName = "MaybeMap";
