import "../CorePrinciplesPieChart/styles.scss";
import "./styles.scss";
import React, { useState } from "react";
import {
  CorePrinciplesPieChart,
  CorePrinciplesPieChartProps,
  handleEvent,
  ChartSectionID,
  FUNDING,
  ENERGY,
  DIVERSITY,
  TRANSPARENCY,
  COMMUNITY,
  POLICY,
  CLICKABLE_CLASSNAMES,
  resolveFillColor,
  resolveTextColor,
} from "../CorePrinciplesPieChart";
import {
  Box,
  Typography,
  ThemeProvider,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { CorePrinciplesIcon } from "../CorePrinciplesIcon";
import { theme } from "../../../theme";
import { useOnDemandMarkupTranslation } from "../../../hooks";

export interface CorePrinciplesContent {
  [(key in ChartSectionID) as string]: string;
}

export interface CorePrinciplesSectionProps
  extends CorePrinciplesPieChartProps {
  content: CorePrinciplesContent;
}

export interface CorePrinciplesListItemsProps
  extends Pick<
      CorePrinciplesPieChartProps,
      "activeChartSection" | "onMouseEnter" | "onMouseLeave"
    >,
    CorePrinciplesSectionProps {}

export const corePrinciplesContent: {
  [key in ChartSectionID as string]: { title: string; content: React.Element };
} = {
  [TRANSPARENCY]: {
    title: TRANSPARENCY,
    content:
      "Industry and community stakeholder engagement across all aspects of project design.",
  },
  [COMMUNITY]: {
    title: COMMUNITY,
    content:
      "Leverage and collaborate on outreach across industry sectors and community groups.",
  },
  [ENERGY]: {
    title: ENERGY,
    content:
      "Promotion of interoperable technology and forward thinking energy management recommendations.",
  },
  [POLICY]: {
    title: POLICY,
    content: "Incentives aligned to enable advancement of MHD ZEV adoption.",
  },
  [FUNDING]: {
    title: FUNDING,
    content:
      "Advisory workgroup enhances applicant access to adequate project funding.",
  },
  [DIVERSITY]: {
    title: DIVERSITY,
    content:
      "Equity metrics are central to all aspects of project approach and design.",
  },
};

export const CorePrinciplesListItems: React.FC<CorePrinciplesListItemsProps> =
  ({ activeChartSection, onMouseEnter, onMouseLeave, content }) => {
    const handleMouseEnter = handleEvent(onMouseEnter);
    const handleMouseLeave = handleEvent(onMouseLeave);
    const getFillColor = resolveFillColor(activeChartSection, "transparent");
    const getTextColor = resolveTextColor(activeChartSection);
    const getClassNames = (item: ChartSectionID) =>
      item === activeChartSection ? "CorePrinciplesListItem--active" : "";

    const Item: React.FC<{ item: string }> = ({ item }) => {
      const title = useOnDemandMarkupTranslation(content[item].title);
      const body = useOnDemandMarkupTranslation(content[item].content);

      return (
        <Box
          key={item}
          onMouseEnter={handleMouseEnter(item)}
          onMouseLeave={handleMouseLeave(item)}
          className={`${CLICKABLE_CLASSNAMES} ${getClassNames(item)}`}
          p={2}
          borderRadius={8}
          style={{
            backgroundColor: getFillColor(item),
            color: getTextColor(item),
          }}
        >
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="CorePrinciplesListItem__wrapper"
          >
            <Typography
              variant="body1"
              className="CorePrinciplesListItem__title"
              component="h3"
              style={{
                color: getTextColor(item),
              }}
            >
              {title}
              <span className="CorePrinciplesListItem__title__underline"></span>
            </Typography>
            <CorePrinciplesIcon variant={item} fill={getTextColor(item)} />
          </Box>
          <Typography
            variant="body2"
            className="CorePrinciplesListItem__content"
          >
            {body}
          </Typography>
        </Box>
      );
    };

    return (
      <>
        {Object.keys(content).map((item) => (
          <Item key={item} item={item} />
        ))}
      </>
    );
  };

export const CorePrinciplesSection: React.FC<CorePrinciplesSectionProps> = ({
  activeChartSection: initialActiveChartSection = undefined,
  content = corePrinciplesContent,
}) => {
  const [activeChartSection, setActiveChartSection] = useState(
    initialActiveChartSection
  );
  const handleMouseLeave = () => setActiveChartSection(undefined);
  const isLargeEnoughScreen = useMediaQuery(useTheme().breakpoints.up("md"));

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" alignItems="center">
        {isLargeEnoughScreen && (
          <Box mr={10}>
            <CorePrinciplesPieChart
              onMouseEnter={setActiveChartSection}
              onMouseLeave={handleMouseLeave}
              activeChartSection={activeChartSection}
            />
          </Box>
        )}
        <Box flexGrow={1}>
          <CorePrinciplesListItems
            activeChartSection={activeChartSection}
            onMouseEnter={setActiveChartSection}
            onMouseLeave={handleMouseLeave}
            content={content}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

CorePrinciplesSection.displayName = "CorePrinciplesSection";
