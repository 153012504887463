import "./styles.scss";
import React, { useState } from "react";
import { Grid, Box } from "@material-ui/core";
import { ContentWrapper } from "../../../../ContentWrapper";
import { SmoothBg } from "../../../../SmoothBg";
import { MultipleItemsCarousel } from "../../../../MultipleItemsCarousel";
import {
  SectionRenderingComponents,
  ContentComponent,
  ContentContainer,
  sort,
} from "../../../Page";
import { contents } from "../contents";
import { Content } from "../../../../../models";
import { Markdown } from "../../../../Markdown";
import { MaybeMap } from "../../../../MaybeMap";

const components = { contents };

export const props: { [key: string]: any } = {
  "irc.hero": {
    pt: 0,
  },
};

const RESOURCES_ENGLISH_TABS_HANDLES = [
  "irc.resources.electric",
  "irc.resources.hydrogen",
  "irc.resources.project-resources",
  "irc.resources.set-aside",
];
const RESOURCES_SPANISH_TABS_HANDLES = [
  "irc.resources.electric.es",
  "irc.resources.hydrogen.es",
  "irc.resources.project-resources.es",
  // "irc.resources.set-aside.es",
];

export enum Tabs {
  EN,
  ES,
}

export const IrcResourcesWithTabs: React.FC<{ contents: Content[] }> = ({
  contents,
}) => {
  const [tab, setTab] = useState(Tabs.EN);
  const intro: Content | undefined = (contents as Array<Content>).find(
    ({ handle }) => handle === "irc.resources.intro"
  );
  const englishTabContents: Content[] = (contents as Array<Content>).filter(
    ({ handle }) => RESOURCES_ENGLISH_TABS_HANDLES.includes(handle)
  );
  const spanishTabContents: Content[] = (contents as Array<Content>).filter(
    ({ handle }) => RESOURCES_SPANISH_TABS_HANDLES.includes(handle)
  );
  const handleTabChange = (t: Tabs) => () => {
    setTab(t);
  };

  return (
    <ContentWrapper>
      <MaybeMap
        value={intro}
        map={({ id, handle, title, body, picture }: Content) => {
          return (
            <ContentContainer key={id} id={id} handle={handle}>
              <ContentComponent
                id={id}
                handle={handle}
                title={title}
                body={body}
                picture={picture}
                components={components}
              />
            </ContentContainer>
          );
        }}
      />
      <div>
        <div className="Tabs__bar">
          <button
            className="Tab__button"
            data-active={tab === Tabs.EN}
            onClick={handleTabChange(Tabs.EN)}
          >
            English
          </button>
          <button
            className="Tab__button"
            data-active={tab === Tabs.ES}
            onClick={handleTabChange(Tabs.ES)}
          >
            Español
          </button>
        </div>
        <div>
          {tab === Tabs.EN && (
            <MaybeMap
              value={sort(englishTabContents)}
              map={({ id, handle, title, body, picture }: Content) => {
                return (
                  <ContentContainer key={id} id={id} handle={handle}>
                    <ContentComponent
                      id={id}
                      handle={handle}
                      title={title}
                      body={body}
                      picture={picture}
                      components={components}
                    />
                  </ContentContainer>
                );
              }}
            />
          )}
          {tab === Tabs.ES && (
            <MaybeMap
              value={sort(spanishTabContents)}
              map={({ id, handle, title, body, picture }: Content) => {
                return (
                  <ContentContainer key={id} id={id} handle={handle}>
                    <ContentComponent
                      id={id}
                      handle={handle}
                      title={title}
                      body={body}
                      picture={picture}
                      components={components}
                    />
                  </ContentContainer>
                );
              }}
            />
          )}
        </div>
      </div>
    </ContentWrapper>
  );
};

export const sections: SectionRenderingComponents = {
  "irc.resources-with-tabs": IrcResourcesWithTabs,
  "irc.hero": ({ contents }) => (
    <>
      {sort(contents).map(({ id, handle, title, body, picture }) => {
        return (
          <ContentContainer key={id} id={id} handle={handle}>
            <ContentComponent
              id={id}
              handle={handle}
              title={title}
              body={body}
              picture={picture}
              components={components}
            />
          </ContentContainer>
        );
      })}
    </>
  ),
  "irc.planning-for-success": ({ contents }) => (
    <ContentWrapper>
      <Grid spacing={5} container>
        {sort(contents).map(({ id, handle, title, body, picture }) => {
          return (
            <Grid
              key={id}
              item
              md={handle === "irc.planning-for-success.intro" ? 12 : 6}
              xs={12}
            >
              <ContentContainer
                id={id}
                handle={handle}
                style={{
                  display:
                    handle !== "irc.planning-for-success.intro"
                      ? "flex"
                      : "block",
                  height: "100%",
                }}
              >
                <ContentComponent
                  id={id}
                  handle={handle}
                  title={title}
                  body={body}
                  picture={picture}
                  components={components}
                />
              </ContentContainer>
            </Grid>
          );
        })}
      </Grid>
    </ContentWrapper>
  ),
  "irc.workshops-and-webinars": ({ contents = [] }) => {
    const intro: Content | undefined = (contents as Array<Content>).find(
      ({ handle }) => handle === "irc.workshops-and-webinars.intro"
    );
    const subscribeRow: Content | undefined = (contents as Array<Content>).find(
      ({ handle }) => handle === "irc.workshops-and-webinars.signup"
    );
    const slides = sort(
      (contents as Array<Content>).filter(({ handle }) =>
        handle.includes("slide")
      )
    );

    return (
      <SmoothBg variant="green">
        <ContentWrapper>
          <Box>
            {intro &&
              [intro].map(({ id, handle, title, body, picture }) => (
                <ContentContainer key={id} id={id} handle={handle}>
                  <ContentComponent
                    id={id}
                    handle={handle}
                    title={title}
                    body={body}
                    picture={picture}
                    components={components}
                  />
                </ContentContainer>
              ))}
            <Box mt={5}>
              <MultipleItemsCarousel
                items={slides.map(({ id, handle, body, picture }) => (
                  <ContentContainer key={id} id={id} handle={handle}>
                    <Markdown>{body}</Markdown>
                  </ContentContainer>
                ))}
              />
            </Box>
            {subscribeRow && (
              <Box mt={10}>
                {[subscribeRow].map(({ id, handle, title, body, picture }) => (
                  <ContentContainer key={id} id={id} handle={handle}>
                    <ContentComponent
                      id={id}
                      handle={handle}
                      title={title}
                      body={body}
                      picture={picture}
                      components={components}
                    />
                  </ContentContainer>
                ))}
              </Box>
            )}
          </Box>
        </ContentWrapper>
      </SmoothBg>
    );
  },
};
