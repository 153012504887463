import React from "react";
import { Grid } from "@material-ui/core";
import { HorizontalSteps } from "../../../HorizontalSteps";
import {
  useSectionData,
  useOnDemandMarkupTranslation,
} from "../../../../hooks";
import { sort } from "../Page";
import { WithResolvedPictureURL } from "../WithResolvedPictureURL";
import { ImageText } from "../../../ImageText";
import { Markdown } from "../../../Markdown";

export enum RenderingOptions {
  HorizontalSteps = "horizontal-steps",
  ImageTextGrid = "image-text-grid",
}

export interface SectionProps {
  /**
   * the page handle property
   */
  page: string;
  /**
   * the section handle property
   */
  section: string;
  /**
   * the way the section content is to be rendered
   */
  as: RenderingOptions;
  /**
   * number of columns for the grid when the `as` property is "image-text-grid"
   */
  cols?: number;
  /**
   * ImageText props to change some behavior of ImageText like with or without OffBg
   */
  imageTextProps?: { [key: string]: any };
}

export const Section: React.FC<SectionProps> = ({
  page,
  section,
  as,
  cols = 2,
  imageTextProps = {},
}) => {
  const { section: data, error, isLoading } = useSectionData(page, section);

  if (error || isLoading) {
    return null;
  }

  switch (as) {
    case RenderingOptions.HorizontalSteps:
      return (
        <HorizontalSteps
          steps={sort(data.contents || []).map(({ title }) => ({
            content: title,
          }))}
        />
      );
    case RenderingOptions.ImageTextGrid:
      const ImageTextGridRender = ({
        body: originalBody,
        title: originalTitle,
        picture,
        handle,
        id,
      }) => {
        const body = useOnDemandMarkupTranslation(originalBody);
        const title = useOnDemandMarkupTranslation(originalTitle);
        const content = <Markdown>{body}</Markdown>;

        return (
          <Grid
            md={12 / cols}
            xs={12}
            key={id}
            data-handle={handle}
            data-id={id}
            data-model="content"
            item
          >
            <ImageText
              imageURL={picture}
              expandedContent={content}
              {...imageTextProps}
            >
              {title}
            </ImageText>
          </Grid>
        );
      };

      return data ? (
        <Grid
          spacing={4}
          data-model="section"
          data-handle={section}
          data-id={data.id}
          container
        >
          {sort(data.contents || []).map(
            WithResolvedPictureURL(ImageTextGridRender)
          )}
        </Grid>
      ) : null;
    default:
      return null;
  }
};

Section.displayName = "Section";
