import React from "react";
import Footer from "../Footer";
import NavigationMenu from "../NavigationMenu";

const HeaderFooter = ({ children }) => {
    return (
        <React.Fragment>
            <NavigationMenu />
            {children}
            <Footer />
        </React.Fragment>
    );
};

export default HeaderFooter;
