/**
 * @link https://github.com/KhaledMohamedP/translate-json-object#example
 */
let googleApiKey;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "production":
    googleApiKey = process.env.REACT_APP_PRODUCTION_GOOGLE_TRANSLATE_API_KEY;
    break;
  default:
    googleApiKey = process.env.REACT_APP_DEVELOPMENT_GOOGLE_TRANSLATE_API_KEY;
}

export const TJO = require("translate-json-object")();

TJO.init({ googleApiKey });
