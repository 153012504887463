import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetPagesQuery } from "../../services/amplify";
import {
  updateContent,
  updateErrorState,
  updateLoadingState,
} from "../../slices/contentsSlice";
import { Languages } from "../../constants";

export function useContents(handle: string) {
  const contents = useSelector((state) => state.contents);
  const dispatch = useDispatch();
  const {
    data = [],
    error,
    isLoading: amplifyContentIsLoading,
  } = useGetPagesQuery(null);
  const addContent = useCallback(
    (language: Languages, content: { [key: string]: any }) => {
      dispatch(updateContent({ language, content }));
    },
    [dispatch]
  );
  const updateError = useCallback(
    (error: any) => dispatch(updateErrorState(error)),
    [dispatch]
  );
  const updateLoading = useCallback(
    (isLoading: boolean) => dispatch(updateLoadingState(isLoading)),
    [dispatch]
  );

  useEffect(() => {
    updateError(error);

    if (amplifyContentIsLoading || error || data.length === 0) {
      return;
    }

    addContent(Languages.EN, { pages: data });
    addContent(Languages.ES, { pages: data });
    updateLoading(false);
  }, [
    updateError,
    updateLoading,
    addContent,
    dispatch,
    data,
    amplifyContentIsLoading,
    error,
  ]);

  return {
    contents,
  };
}
