import React from "react";
import { TransparencyIcon } from "./TransparencyIcon";
import { CommunityIcon } from "./CommunityIcon";
import { EnergyIcon } from "./EnergyIcon";
import { PolicyIcon } from "./PolicyIcon";
import { FundingIcon } from "./FundingIcon";
import { DiversityIcon } from "./DiversityIcon";
import {
  ChartSectionID,
  COMMUNITY,
  DIVERSITY,
  ENERGY,
  FUNDING,
  POLICY,
  TRANSPARENCY,
} from "../CorePrinciplesPieChart";

export interface CorePrinciplesIconProps {
  variant: ChartSectionID;
  fill?: string;
}

export const iconsMap: {
  [key in ChartSectionID as string]: React.FC<
    Pick<CorePrinciplesIconProps, "fill">
  >;
} = {
  [TRANSPARENCY]: TransparencyIcon,
  [COMMUNITY]: CommunityIcon,
  [ENERGY]: EnergyIcon,
  [POLICY]: PolicyIcon,
  [FUNDING]: FundingIcon,
  [DIVERSITY]: DiversityIcon,
};

export const CorePrinciplesIcon: React.FC<CorePrinciplesIconProps> = ({
  variant,
  fill,
}) => {
  const Icon = iconsMap[variant];

  return (
    <div>
      <Icon fill={fill} />
    </div>
  );
};

CorePrinciplesIcon.displayName = "CorePrinciplesIcon";
