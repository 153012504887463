import { createApi } from "@reduxjs/toolkit/query/react";
import { gql, ClientError, GraphQLClient } from "graphql-request";

var url, API_KEY;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "production":
    url = process.env.REACT_APP_AMPLIFY_BASE_URL_PRODUCTION;
    API_KEY = process.env.REACT_APP_AMPLIFY_API_KEY_PRODUCTION;
    break;
  default:
    url = process.env.REACT_APP_AMPLIFY_BASE_URL_STAGING;
    API_KEY = process.env.REACT_APP_AMPLIFY_API_KEY_STAGING;
}

const client = new GraphQLClient(url);
const headers = {
  "X-Api-Key": API_KEY,
  "Content-Type": "application/json",
};

const graphqlBaseQuery =
  (vars = {}) =>
  async ({ body }) => {
    try {
      const result = await client.request(body, vars, headers);

      return { data: result };
    } catch (error) {
      if (error instanceof ClientError) {
        return { error: { status: error.response.status, data: error } };
      }

      return { error: { status: 500, data: error } };
    }
  };

const excludeDeleted = ({ deleted }) => !deleted;

export const amplifyApi = createApi({
  reducerPath: "pages",
  baseQuery: graphqlBaseQuery(),
  endpoints: (builder) => ({
    getPages: builder.query({
      query: () => ({
        body: gql`
          query ListPages {
            listPages {
              items {
                id
                handle
                name
                order
                published
                _deleted
                Sections {
                  items {
                    section {
                      id
                      title
                      handle
                      order
                      published
                      _deleted
                      contents {
                        items {
                          content {
                            id
                            handle
                            title
                            body
                            order
                            published
                            picture
                            _deleted
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      }),
      transformResponse: (response) => {
        const pages = response.listPages.items
          .filter(excludeDeleted)
          .map(({ id, handle, name, order, published, Sections }) => {
            return {
              id,
              handle,
              name,
              order,
              published,
              sections: Sections.items
                .filter(excludeDeleted)
                .map(({ section }) => {
                  return {
                    ...section,
                    contents: section.contents.items
                      .filter(excludeDeleted)
                      .map(({ content }) => content),
                  };
                }),
            };
          });

        return pages;
      },
    }),
  }),
});

export const { useGetPagesQuery, middleware } = amplifyApi;
