import "./styles.scss";
import React, { CSSProperties } from "react";
import { Top, Bottom, SVGProps } from "./Bg";
import { useBoundingClientRect } from "../../hooks/useBoundingClientRect";
import { Box } from "@material-ui/core";

export type Variant = "blue" | "green";

export const variants: Readonly<{ [key: string]: string }> = {
  blue: "#E5EDF5",
  green: "#DEE7E3",
};

const getStyles = (
  topRect: DOMRect
): {
  top: CSSProperties;
  bottom: CSSProperties;
} => {
  return {
    top: { top: `-${Math.ceil(topRect.height / 2)}px` },
    bottom: { bottom: `-${Math.ceil(topRect.height / 2)}px` },
  };
};

export interface SmoothBgProps extends Partial<Pick<SVGProps, "variant">> {
  hideBottom?: boolean;
  hideTop?: boolean,
}

export const SmoothBg: React.FC<SmoothBgProps> = ({
  children,
  variant = "blue",
  hideBottom = false,
  hideTop,
  ...props
}) => {
  const { rect: topSvgRect, ref: topRef } = useBoundingClientRect();
  const styles = getStyles(topSvgRect);

  return (
    <Box className="SmoothBg" data-variant={variant} {...props}>
      <Top
        variant={variant}
        ref={topRef}
        className="SmoothBg__top"
        style={styles.top}
      />
      {!hideBottom &&
      (<Bottom
        variant={variant}
        className="SmoothBg__bottom"
        style={styles.bottom}
      />)}
      <div className="SmoothBg__content-container">{children}</div>
    </Box>
  );
};

SmoothBg.displayName = "SmoothBg";
