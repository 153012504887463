import React from "react";
import { Markdown } from "../Markdown";
import { FramedImageFlag, FramedImageFlagProps } from "../../FramedImageFlag";
import { ParagraphTextProps } from "../../ParagraphText";
import { Content } from "../../../models";
import { WithResolvedPictureURL } from "../../../components/pages/Page/WithResolvedPictureURL";
import { WithWrappingBox } from "../../../components/pages/Page/WithWrappingBox";

export const WithVerticalSpacing = (Component: React.FC<Content>) =>
  function RenderComponentWithVerticalSpacing(props: Content) {
    const WrappedComponent = WithWrappingBox({ py: { xs: 4, md: 8 } })(
      Component
    );

    return <WrappedComponent {...props} />;
  };

export interface MarkdownFramedImageFlagProps
  extends Pick<FramedImageFlagProps, "reverse">,
    Pick<ParagraphTextProps, "kind" | "variant">,
    Partial<Pick<Content, "title" | "picture" | "body">> {}

export const MarkdownFramedImageFlag: React.FC<MarkdownFramedImageFlagProps> =
  WithVerticalSpacing(
    WithResolvedPictureURL(
      ({
        title,
        picture,
        body,
        kind = "default",
        variant = "h2",
        reverse = false,
        pictureBackgroundColor,
      }) => {
        return (
          <FramedImageFlag
            kind={kind}
            title={title || ""}
            src={picture || ""}
            reverse={reverse}
            pictureBackgroundColor={pictureBackgroundColor}
            variant={variant}
          >
            <Markdown>{body}</Markdown>
          </FramedImageFlag>
        );
      }
    )
  );

MarkdownFramedImageFlag.displayName = "MarkdownFramedImageFlag";

export const ReversedMarkdownFramedImageFlag: React.FC<MarkdownFramedImageFlagProps> =
  (props) => <MarkdownFramedImageFlag {...props} reverse />;

ReversedMarkdownFramedImageFlag.displayName = "ReversedMarkdownFramedImageFlag";

export const ReversedMarkdownFramedImageFlagWithMainTitle: React.FC<MarkdownFramedImageFlagProps> =
  (props) => (
    <MarkdownFramedImageFlag {...props} kind="main" variant="h1" reverse />
  );

ReversedMarkdownFramedImageFlagWithMainTitle.displayName =
  "ReversedMarkdownFramedImageFlagWithMainTitle";
