import { RefAttributes, useLayoutEffect, useState } from "react";

export function useImageHasLoaded({
  src,
  ref,
}: {
  src: string;
  ref: RefAttributes<HTMLImageElement>;
}) {
  const [hasLoaded, setHasLoaded] = useState(false);

  useLayoutEffect(() => {
    const current = ref?.current;

    if (!current || !src || !current?.decode) {
      return;
    }

    /**
     * @link https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/decode
     */

    (current as HTMLImageElement)?.decode().then(() => {
      const complete = current?.complete;
      const hasHeight = current?.naturalHeight > 0;

      setHasLoaded(complete && hasHeight);
    });
  }, [ref, src]);

  return { hasLoaded };
}
