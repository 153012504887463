import "react-alice-carousel/lib/alice-carousel.css";
import React from "react";
import AliceCarousel, {
  AnimationType,
  AutoplayDirection,
} from "react-alice-carousel";

export interface CarouselProps {
  className?: string;
  onInitialized?: () => void;
  onResized?: () => void;
  prevButton?: (({ isDisabled }: { isDisabled: any }) => any) | undefined;
  nextButton?: (({ isDisabled }: { isDisabled: any }) => any) | undefined;
  items?: Array<React.ReactNode>;
  animationType?: "slide" | "fadeout" | AnimationType | undefined;
  animationDuration?: number;
  autoPlayDirection?: "ltr" | "rtl" | AutoplayDirection | undefined;
  responsive?: { [key: number]: { items: number } };
  controlsStrategy?: string;
  disableDotsControls?: boolean;
}

const noop = () => null;

export const Carousel: React.FC<CarouselProps> = ({
  className = "",
  onInitialized: handleInitialized = noop,
  onResized: handleResized = noop,
  prevButton: PrevButton = undefined,
  nextButton: NextButton = undefined,
  items = [],
  animationType = "slide",
  animationDuration = 800,
  autoPlayDirection = "ltr",
  responsive = {
    0: { items: 1 },
    576: { items: 1 },
    768: { items: 1 },
    1024: { items: 1 },
  },
  controlsStrategy = "responsive",
  disableDotsControls = true,
}) => {
  return (
    <div className={className}>
      <AliceCarousel
        onInitialized={handleInitialized}
        onResized={handleResized}
        renderPrevButton={PrevButton}
        renderNextButton={NextButton}
        items={items}
        animationType={animationType}
        animationDuration={animationDuration}
        autoPlayDirection={autoPlayDirection}
        disableDotsControls={disableDotsControls}
        mouseTracking
        infinite
        responsive={responsive}
        controlsStrategy={controlsStrategy}
      />
    </div>
  );
};

Carousel.displayName = "Carousel";
