/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MouseEventHandler } from "react";

export interface NavigationFlatButtonProps {
    className?: string;
    children: React.ReactNode;
    fwdRef: React.Ref<HTMLDivElement>;
    onClick: MouseEventHandler<HTMLDivElement>
};

export default function NavigationFlatButton ({
    className = '',
    children,
    fwdRef,
    onClick,
    ...props
}: NavigationFlatButtonProps) {
    return (
        <div
            ref={fwdRef}
            className={`NavigationFlatButton ${className}`}
            onClick={(event) => { onClick(event); event.currentTarget.blur(); }}
            role="button"
            tabIndex={0}
            {...props}
        >
            {children}
        </div>
    );
}