import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "../../theme";
import { Markdown } from "../Markdown";
import { useOnDemandMarkupTranslation, useCopy } from "../../hooks";

export type Variant = "green" | "yellow";

export interface StepProps {
  index: number;
  text: string;
  variant: Variant;
}

export const Step: React.FC<StepProps> = ({
  index,
  text: initialText,
  variant,
  ...props
}) => {
  const text = useOnDemandMarkupTranslation(initialText);
  const { step } = useCopy();

  return (
    <ThemeProvider theme={theme}>
      <Box className="Step" data-variant={variant} {...props}>
        <Box mr={3}>
          <Typography className="Step__number">{index}</Typography>
        </Box>
        <Box className="Step__content">
          <Typography className="Step__title">
            {step} {index}
          </Typography>
          <Markdown>{text}</Markdown>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

Step.displayName = "Step";
