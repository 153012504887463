import React from "react";
import { useAmplifyContentImage, useAmplifyFileUrl } from "../../../../hooks";

export interface ImageProps {
  name: string;
  alt?: string;
}

export const Image: React.FC<ImageProps> = ({ name, alt }) => {
  const { url } = useAmplifyContentImage(name);

  return url ? <img src={url} alt={alt || name} /> : null;
};

export interface LinkProps {
  name: string;
  title?: string;
  download?: boolean;
  className?: string;
}

export const Link: React.FC<LinkProps> = ({
  name,
  title,
  download = false,
  children,
  className = "",
}) => {
  const { url } = useAmplifyFileUrl(name);

  return url ? (
    <a
      href={url}
      title={title || name}
      target="_blank"
      rel="noopener noreferrer"
      download={download}
      className={`Link ${className}`}
    >
      {children}
    </a>
  ) : null;
};

export const Pdf: React.FC<Pick<LinkProps, "name" | "title"> & { className?: string }> = ({
  className,
  name,
  title,
  children,
}) => (
  <Link name={name} title={title} className={`Pdf ${className}`} download>
    {children}
  </Link>
);

export enum RenderingOptions {
  Image = "image",
  Link = "link",
  Pdf = "pdf",
}

export interface FileProps {
  name: string;
  as: RenderingOptions;
}

export const File: React.FC<FileProps & LinkProps & ImageProps> = (props) => {
  switch (props?.as) {
    case RenderingOptions.Image:
      return <Image {...props} />;
    case RenderingOptions.Link:
      return <Link {...props} />;
    case RenderingOptions.Pdf:
      return <Pdf {...props} />;
    default:
      return null;
  }
};
