import { useState, useLayoutEffect } from "react";
import { Storage } from "aws-amplify";

export function useAmplifyFileUrl(
  name: string | null | undefined,
) {
  const [url, setUrl] = useState<string | undefined>(null);

  useLayoutEffect(() => {
    if (!name) {
      return;
    }

    Storage.get(name, { level: "public" ,expires:604800}).then((url) => {
      setUrl(url);
    });
  }, [name, setUrl]);

  return { url };
}

