import React from "react";
import { Section } from "../../../models";
import { Page } from "../Page";
import { contents, sections } from "./components";

export interface ParticipateProps {}

export const PARTICIPATE = "participate";
export const path = PARTICIPATE;
export const title = "Participate";

const excludeSection = (section: Section) =>
  section.handle !== "participate.choose-your-funding-lane";

const filters = {
  sections: excludeSection,
};

export const Participate: React.FC<ParticipateProps> = () => {
  return (
    <Page
      handle={PARTICIPATE}
      components={{ contents, sections }}
      filters={filters}
      pt={0}
      pb={6}
    />
  );
};

Participate.displayName = "Participate";
