import "./styles.scss";
import React from "react";
import { Box } from "@material-ui/core";

export interface DefaultBoundaryProps {
  className?: string;
}

export const DefaultBoundary: React.FC<DefaultBoundaryProps> = ({
  children,
  className = "ContentWrapper__boundary",
}) => (
  <Box
    className={className}
    py={{ xs: 3, sm: 4, md: 5, lg: 6}}
  >
    {children}
  </Box>
);

export interface ContentWrapperProps {
  boundaryComponent?: typeof DefaultBoundary;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({
  children,
  boundaryComponent = DefaultBoundary,
}) => {
  const Boundary: typeof DefaultBoundary = boundaryComponent;

  return (
    <div className="ContentWrapper">
      <Boundary className="ContentWrapper__boundary">{children}</Boundary>
    </div>
  );
};

ContentWrapper.displayName = "ContentWrapper";
