import "./styles.scss";
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import { Button } from "@material-ui/core";
import { resolveCardsHeights } from "./resolveCardsHeights";

const ItemContainer = ({ children }) => (
  <div className="ItemContainer">{children}</div>
);

const NavButton = ({ children, ...props }) => (
  <Button className="NavButton" {...props}>
    {children}
  </Button>
);

const PrevButton = (props) => (
  <NavButton {...props}>
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27" cy="27" r="27" fill="#314962" />
      <path
        d="M20.6387 27.6367L29.6377 18.6377V27.6367V36.6357L20.6387 27.6367Z"
        fill="white"
      />
    </svg>
  </NavButton>
);

const NextButton = (props) => (
  <NavButton {...props}>
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="27" transform="matrix(-1 0 0 1 27 27)" fill="#314962" />
      <path
        d="M33.3613 27.6367L24.3623 18.6377V27.6367V36.6357L33.3613 27.6367Z"
        fill="white"
      />
    </svg>
  </NavButton>
);

const TIMEOUT = 4000;

export const MultipleItemsCarousel = ({ items }) => {
  const [height, setHeight] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const ref = useRef();
  const handleResize = () => resolveCardsHeights(ref, setHeight);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const images = [].slice.call(ref.current.getElementsByTagName("img"));

    setAllImagesLoaded(
      images.every((image) => image.naturalHeight > 0 && image.complete)
    );

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  useLayoutEffect(() => {
    if (!allImagesLoaded) {
      return;
    }

    const timeout = setTimeout(() => {
      handleResize();
      clearTimeout(timeout);
    }, TIMEOUT);
  }, [allImagesLoaded]);

  return (
    <div ref={ref}>
      <style type="text/css">
        {height ? `.WebinarCard--container { height: ${height}px; }` : ""}
      </style>
      <AliceCarousel
        renderPrevButton={PrevButton}
        renderNextButton={NextButton}
        items={items.map((item, i) => (
          <ItemContainer key={`carousel-item-${i}`}>{item}</ItemContainer>
        ))}
        animationType="slide"
        animationDuration={800}
        autoPlayDirection="ltr"
        stopAutoPlayOnHover
        disableDotsControls
        mouseTracking
        infinite
        responsive={{
          0: { items: 1 },
          576: { items: 1 },
          768: { items: 2 },
          1024: { items: 3 },
        }}
        controlsStrategy="responsive"
      />
    </div>
  );
};
