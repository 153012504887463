import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Content } from "../../../../models";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "../../../../theme";

export const WithWrappingBox =
  (boxProps: BoxProps = {}) =>
  (Component: React.FC<Content>) =>
    function RenderComponentWithWrappingBox(props: Content) {
      return (
        <ThemeProvider theme={theme}>
          <Box {...boxProps}>
            <Component {...props} />
          </Box>
        </ThemeProvider>
      );
    };
