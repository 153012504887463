import React from "react";
import { Typography, TypographyProps, ThemeProvider } from "@material-ui/core";
import { theme } from "../../theme";

export interface ParagraphProps
  extends Partial<Pick<TypographyProps, "component" | "variant">> {}

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  component = "p",
  variant = "body1",
  ...props
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Typography component={component} variant={variant} {...props}>
        {children}
      </Typography>
    </ThemeProvider>
  );
};

Paragraph.displayName = "Paragraph";
