import React from "react";
import { variants } from "../../SmoothBg";
import { Page } from "../Page";
import { contents, sections, sectionProps } from "./components";

export interface NewsProps {}

export const NEWS = "news";
export const path = "updates";
export const title = "News";

export const News: React.FC<NewsProps> = () => {
  return (
    <Page
      handle={NEWS}
      components={{ contents, sections }}
      props={{ sections: sectionProps }}
      pt={0}
      pb={0}
      backgroundColor={variants.green}
    />
  );
};

News.displayName = "News";
