import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

export function useAnimateOnceOnScroll() {
  const [animate, setAnimate] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  useEffect(() => {
    if (!inView) {
      return;
    }

    if (!animate) {
      setAnimate(true);
    }
  }, [inView, animate]);

  return { ref, animate };
}
