import "./styles.scss";
import React from "react";
import { Box } from "@material-ui/core";
import { FlagWithCtas, FlagWithCtasProps } from "../FlagWithCtas";
import { ParagraphTextProps } from "../ParagraphText";
import { ParagraphTextWithAnimatedTitle } from "../ParagraphTextWithAnimatedTitle";
import {
  PictureWithOffBorder,
  PictureWithOffBorderProps,
} from "../PictureWithOffBorder";
import { useAnimateOnceOnScroll } from "../../hooks";

export interface FramedImageFlagProps
  extends Partial<FlagWithCtasProps>,
    Pick<PictureWithOffBorderProps, "src">,
    Partial<Pick<PictureWithOffBorderProps, "alt">>,
    Pick<ParagraphTextProps, "kind" | "variant"> {
  title: string;
  className?: string;
  pictureBackgroundColor?: string;
}

export const FramedImageFlag: React.FC<FramedImageFlagProps> = ({
  ctas = [],
  title,
  src,
  alt,
  children,
  className = "",
  kind,
  pictureBackgroundColor,
  variant,
  ...props
}) => {
  const { animate, ref } = useAnimateOnceOnScroll();

  return (
    <div ref={ref}>
      <FlagWithCtas
        ctas={ctas}
        className={`FramedImageFlag ${className}`}
        {...props}
      >
        <Box className="FramedImageFlag__picture-container">
          <PictureWithOffBorder
            src={src}
            alt={alt || title}
            animate={animate}
            backgroundColor={pictureBackgroundColor}
          />
        </Box>
        <Box
          className="FramedImageFlag__content"
          mt={{ xs: 3, md: 0 }}
          data-reversed={props.reverse}
        >
          <ParagraphTextWithAnimatedTitle
            title={title}
            kind={kind}
            variant={variant}
          >
            {children}
          </ParagraphTextWithAnimatedTitle>
        </Box>
      </FlagWithCtas>
    </div>
  );
};

FramedImageFlag.displayName = "FramedImageFlag";
