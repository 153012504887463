import "./styles.scss";
import React from "react";
import {
  CardContent,
  Typography,
  Box,
  BoxProps,
  ThemeProvider,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { BoxWithOffBg } from "../../BoxWithOffBg";
import { TextLink } from "../../TextLink";
import { theme } from "../../../theme";
import { GradientBg } from "../../GradientBg";
import {
  useOnDemandMarkupTranslation,
  useCopy,
} from "../../../hooks";
import { Pdf } from "../../pages/Page/File";

export const ContentWrapper: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box flexGrow="1" display="flex" flexDirection="column" {...props}>
    {children}
  </Box>
);

ContentWrapper.displayName = "ContentWrapper";

export interface WebinarCardProps extends Partial<BoxProps> {
  image: string;
  title: string;
  date: string;
  content: string;
  watchTime?: string;
  link?: string;
  isDownload?: boolean;
}

export const WebinarCard: React.FC<WebinarCardProps> = ({
  image,
  title: initialTitle,
  date: initialDate,
  content: initialContent,
  watchTime: initialWatchTime,
  link,
  isDownload,
  ...props
}) => {
  const title = useOnDemandMarkupTranslation(initialTitle);
  const date = useOnDemandMarkupTranslation(initialDate);
  const content = useOnDemandMarkupTranslation(initialContent);
  const watchTime = useOnDemandMarkupTranslation(initialWatchTime);
  const { learn_more, watch } = useCopy();

  return (
    <ThemeProvider theme={theme}>
      <BoxWithOffBg
        components={{ contentWrapper: ContentWrapper }}
        className="WebinarCard--container"
        {...props}
      >
        <img src={image} alt={title} />
        <CardContent component={GradientBg}>
          <Box flexGrow="1">
            <Box marginBottom={5}>
              <Typography variant="h3">{title}</Typography>
            </Box>
            <Box marginBottom={2}>
              <Typography component="em" variant="body2">
                {date}
              </Typography>
            </Box>
            <Box marginBottom={5}>
              <Typography variant="body2">{content}</Typography>
            </Box>
            {watchTime && (
              <Box display="flex" alignItems="center" marginBottom={4}>
                <Box component="span" marginRight={1}>
                  <AccessTimeIcon />
                </Box>
                {watchTime && (
                  <Typography variant="body2">{watchTime}</Typography>
                )}
              </Box>
            )}
          </Box>
          {
            isDownload && link ? (
                <Box justifySelf="flex-end">
                  <Pdf className="learn-button" name={link} title={link}>
                    <strong>{learn_more}</strong>
                  </Pdf>
                </Box>
              ) : link ? (
              <Box justifySelf="flex-end">
                <TextLink
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  color="white"
                >
                  <strong>{watch}</strong>
                </TextLink>
              </Box>
            ) : null
          }
        </CardContent>
      </BoxWithOffBg>
    </ThemeProvider>
  );
};

WebinarCard.displayName = "WebinarCard";
