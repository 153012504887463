import "./styles.scss";
import React, { CSSRules } from "react";
import { Box, Typography, ThemeProvider } from "@material-ui/core";
import { Markdown } from "../../../../../Markdown";
import { WithResolvedPictureURL } from "../../../../Page/WithResolvedPictureURL";
import { WithExtractedMarkdownLinkBlock } from "../../../../Page/WithExtractedMarkdownLinkBlock";
import { theme } from "../../../../../../theme";

export interface PlanningBoxProps {
  picture: string;
  title: string;
  body: string;
  link: string;
  style: CSSRules;
}

const Arrow: React.FC = () => (
  <span className="PlanningBox__arrow">
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.541992 8.54199V1.83144e-07L9.08398 8.54199L0.541992 17.084V8.54199Z"
        fill="#314962"
      />
    </svg>
  </span>
);

Arrow.displayName = "Arrow";

export const PlanningBox: React.FC<PlanningBoxProps> = WithResolvedPictureURL(
  WithExtractedMarkdownLinkBlock(
    ({ picture, title, body, link, style = {} }) => {
      return (
        <ThemeProvider theme={theme}>
          <Box
            component="a"
            p={2}
            className="PlanningBox"
            role="button"
            href={link}
            rel="noopener noreferrer"
            style={style}
          >
            <Box className="PlanningBox__img-container">
              <img src={picture} alt={title} />
            </Box>
            <Box className="PlanningBox__content">
              <div>
                <Typography component="span" className="PlanningBox__title">
                  {title}
                </Typography>
                <div className="PlanningBox__body">
                  <Markdown>{body}</Markdown>
                </div>
              </div>
              <Arrow />
            </Box>
          </Box>
        </ThemeProvider>
      );
    }
  )
);

PlanningBox.displayName = "PlanningBox";
