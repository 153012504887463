import React, { useEffect } from "react";

export interface ContactProps {}

export const CONTACT = "contact";
export const path = CONTACT;
export const title = "Contact";

const CONTACT_FORM = "https://us20.list-manage.com/contact-form?u=97fe5ebae707b7479faeb8640&form_id=7f1004dcdf64dd293adb0c140f0a216e";

export const ContactPage: React.FC<ContactProps> = () => {
  useEffect(() => {
    window.open(
      CONTACT_FORM,
      '_blank',
    );
    window.history.back();
  }, []);
  
  return null;
};

ContactPage.displayName = "ContactPage";
