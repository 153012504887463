import React from "react";

export interface YoutubeVideoProps {
  code: string;
  title?: string;
}

export const YoutubeVideo: React.FC<YoutubeVideoProps> = ({ code, title }) => {
  return (
    <iframe
      src={`https://www.youtube.com/embed/${code}?controls=0`}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

YoutubeVideo.displayName = "YoutubeVideo";
