import React from "react";
import { Grid, Box } from "@material-ui/core";
import { ContentWrapper } from "../../../../ContentWrapper";
import { SmoothBg } from "../../../../SmoothBg";
import {
  SectionRenderingComponents,
  ContentComponent,
  ContentContainer,
  sort,
} from "../../../Page";
import { contents } from "../contents";

const components = { contents };

export const ParticipateIntroContent = ({
  id,
  handle,
  title,
  body,
  picture,
  order,
}) => {
  return (
    <Grid
      key={id}
      item
      xs={12}
      md={12}
      data-model="content"
      data-id={id}
      data-handle={handle}
    >
      <ContentContainer key={id} id={id} handle={handle}>
        <ContentComponent
          id={id}
          handle={handle}
          title={title}
          body={body}
          picture={picture}
          components={components}
          order={order}
        />
      </ContentContainer>
    </Grid>
  );
};

export const ParticipateIntro: React.FC<SectionRenderingComponents> = ({
  contents,
}) => {
  return (
    <SmoothBg mb={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
      <ContentWrapper>
        <Box>
          <Grid spacing={5} container>
            {sort(contents).map((props) => (
              <ParticipateIntroContent {...props} key={props.id} />
            ))}
          </Grid>
        </Box>
      </ContentWrapper>
    </SmoothBg>
  );
};

export const sections: SectionRenderingComponents = {
  "participate.intro": ParticipateIntro,
};
