import "./styles.scss";
import React from "react";
import { Carousel } from "../Carousel";
import { SectionTitle } from "../SectionTitle";
import { Paragraph } from "../Paragraph";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export interface NewsCarouselProps {
  title: string,
  body: string,
  items: Array<React.ReactNode>;
}

export const Section: React.FC<{
  title: string,
  body: string,
}> = ({ title, body, children }) => (
  <div className="NewsCarousel__section">
    <div className="NewsCarousel__section__boundary">
      <div className="NewsCarousel__section--intro">
        <SectionTitle kind="main" color="white">
          {title}
        </SectionTitle>
        <Paragraph color="white">
          {body}
        </Paragraph>
      </div>
      {children}
    </div>
  </div>
);

export const NavButton: React.FC<{
  isDisabled: boolean;
  direction: "previous" | "next";
}> = ({ isDisabled, children, direction }) => {
  return (
    <button
      className="NewsCarousel__button"
      data-direction={direction}
      data-testid={`button-${direction}`}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

NavButton.displayName = "NavButton";

export const PrevButton: React.FC<{ isDisabled: any }> = ({ isDisabled }) => {
  return (
    <NavButton isDisabled={Boolean(isDisabled)} direction="previous">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
          fill="#EDA65A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36 22L26 32L36 42V22Z"
          fill="#314962"
        />
      </svg>
    </NavButton>
  );
};

PrevButton.displayName = "PrevButton";

export const NextButton: React.FC<{ isDisabled: any }> = ({ isDisabled }) => {
  return (
    <NavButton isDisabled={Boolean(isDisabled)} direction="next">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64C49.6731 64 64 49.6731 64 32Z"
          fill="#EDA65A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 22L38 32L28 42V22Z"
          fill="#314962"
        />
      </svg>
    </NavButton>
  );
};

NextButton.displayName = "NextButton";

export const NewsCarouselItemWrapper: React.FC = ({ children }) => {
  return <div className="NewsCarouselItemWrapper">{children}</div>;
};

NewsCarouselItemWrapper.displayName = "NewsCarouselItemWrapper";

export const NewsCarousel: React.FC<NewsCarouselProps> = ({ title, body, items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Section title={title} body={body}>
      <Carousel
        className={`NewsCarousel${isMobile ? ` NewsCarousel--sm` : ''}`}
        prevButton={PrevButton}
        nextButton={NextButton}
        items={items.map((item, index) => (
          <NewsCarouselItemWrapper key={`NewsCarouselItemWrapper-${index}`}>
            {item}
          </NewsCarouselItemWrapper>
        ))}
        disableDotsControls={false}
      />
    </Section>
  );
};

NewsCarousel.displayName = "NewsCarousel";
