import { useLayoutEffect, useState, useRef } from "react";
import { useWindowResize, useDOMContentLoaded } from "..";

const INITIAL_RECT = {
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: 0,
  height: 0,
} as DOMRect;

const DELAY = 300;

export function useBoundingClientRect(
  {
    debounce,
  }: {
    debounce: boolean;
  } = { debounce: true }
) {
  const [rect, setRect] = useState<DOMRect>(INITIAL_RECT);
  const ref = useRef(null);
  const assignRect = () => {
    if (!ref?.current) {
      return;
    }
    setRect(
      (ref.current as HTMLElement).getBoundingClientRect() || INITIAL_RECT
    );
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      assignRect();
    }, DELAY);
  }, [ref]);

  useWindowResize(assignRect, debounce);
  useDOMContentLoaded(assignRect, { delay: true });

  return { ref, rect };
}
