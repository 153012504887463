import "./styles.scss";
import React from "react";
import { Box, Typography, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme";
import { Markdown } from "../../Markdown";
import { useOnDemandMarkupTranslation } from "../../../hooks";

export interface HorizontalStepProps {
  step: number;
  content: string;
}

export const HorizontalStep: React.FC<HorizontalStepProps> = ({
  step,
  content: initialContent,
}) => {
  const content = useOnDemandMarkupTranslation(initialContent);

  return (
    <ThemeProvider theme={theme}>
      <Box
        px={2}
        py={4}
        className="HorizontalStep"
        display="flex"
        alignItems="center"
      >
        <Typography component="span" className="HorizontalStep__step">
          {step}
        </Typography>
        <Typography className="HorizontalStep__content">
          <Markdown>{content}</Markdown>
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

HorizontalStep.displayName = "HorizontalStep";
