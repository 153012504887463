import ReactMarkdown from "react-markdown";
import JsxParser from "react-jsx-parser";
import { Box, Grid } from "@material-ui/core";
import { AppButton } from "../AppButton";
import { Step } from "../Step";
import { YoutubeVideo } from "../YoutubeVideo";
import { DownloadButton } from "../DownloadButton";
import { WebinarCard } from "../cards/WebinarCard";
import { Section } from "../pages/Page/Section";
import { Image, Pdf, Link } from "../pages/Page/File";
import { useAmplifyContentImage } from "../../hooks";
import { Paragraph } from "../Paragraph";

const WebinarCardWithPicture = ({ image, ...props }) => {
  const { url } = useAmplifyContentImage(image);

  return url ? <WebinarCard {...props} image={url} /> : null;
};

const componentTransforms = {
  AppButton,
  Grid,
  Box,
  Step,
  Section,
  Image,
  Pdf,
  Link,
  YoutubeVideo,
  DownloadButton,
  WebinarCard: WebinarCardWithPicture,
  Paragraph,
};

const renderers = {
  code: ({ className, children }) => {
    if (className !== "language-jsx") {
      console.warn("Only `jsx` is allowed here.");
    }

    return (
      <JsxParser
        jsx={children.join("\n")}
        components={componentTransforms}
        renderInWrapper={false}
      />
    );
  },
  pre: ({ children }) => <>{children}</>,
};

export const Markdown = (props) => {
  return (
    <ReactMarkdown
      {...props}
      components={{ ...renderers, ...(props?.components || {}) }}
    >
      {props.children}
    </ReactMarkdown>
  );
};
